import * as S from '../styles';
import { FaCheck, FaExclamation, FaQuestion } from 'react-icons/fa';
import { Typography } from '../../../../../../componentsV2/ui/Typography';
import { convertCentsToFormattedReais } from '../../../../../../utils/CurrencyConvert';
import { Button } from '../../../../../../componentsV2/ui/Button';
import React from 'react';
import { StepProps } from './index';
import { useQuery } from 'react-query';
import { getLimits } from '../../../../../../services/queries/Corpway/Transfers';
import { useAuth } from '../../../../../../hooks/useAuth';

interface LimitWarningProps extends StepProps {
	openLimit: () => void;
}

const LimitWarning = ({ setStep, openLimit }: LimitWarningProps) => {
	const { currentCompany } = useAuth();

	const getLimitQuery = useQuery(
		['getLimits', currentCompany?.id],
		() => getLimits(currentCompany!.id),
		{
			enabled: !!currentCompany,
			staleTime: 0,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		}
	);

	const limits = getLimitQuery.data;

	return (
		<S.GeneralContainer>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2.4rem',
					alignItems: 'center',
				}}
			>
				<S.ModalIconContainer>
					<FaExclamation size={24} color={'white'} />
				</S.ModalIconContainer>
				<S.Description>
					<span>Notamos que o </span>
					<span className={'bold'}>
						valor da transação <br /> ultrapassa o limite{' '}
					</span>
					<span>
						configurado. Solicite uma alteração de limite para prosseguir.
					</span>
				</S.Description>
			</div>
			<S.BorderContainer style={{ width: '100%' }}>
				<S.Row>
					<Typography>Limite diário</Typography>
					<Typography weight={'600'} color={'var(--primary-blue)'}>
						{convertCentsToFormattedReais(limits?.ted.day.amount ?? 0)}
					</Typography>
				</S.Row>
			</S.BorderContainer>
			<S.ButtonsContainer>
				<Button
					roundness={'lg'}
					intent={'terciary'}
					$outline
					onClick={() => {
						setStep(3);
					}}
				>
					Voltar
				</Button>
				<Button
					roundness={'lg'}
					intent={'primary'}
					onClick={() => {
						openLimit();
						setStep(3);
					}}
				>
					Alterar limites
				</Button>
			</S.ButtonsContainer>
		</S.GeneralContainer>
	);
};

const HourWarning = ({ setStep }: StepProps) => {
	return (
		<S.GeneralContainer>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2.4rem',
					alignItems: 'center',
					maxWidth: '36rem',
				}}
			>
				<S.ModalIconContainer>
					<FaExclamation size={24} color={'white'} />
				</S.ModalIconContainer>
				<S.Description style={{ textAlign: 'justify' }}>
					<span>Você pode fazer transferências via TED de </span>
					<span className={'bold'}>
						segunda a sexta-feira, entre 7h e 17h.{' '}
					</span>
					<span>
						Solicitações realizadas fora desse período ou em feriados serão
						processadas no próximo horário disponível ou no dia útil seguinte.
					</span>
				</S.Description>
			</div>
			<S.ButtonsContainer>
				<Button
					roundness={'lg'}
					intent={'terciary'}
					$outline
					onClick={() => {
						setStep(3);
					}}
				>
					Voltar
				</Button>
				<Button
					roundness={'lg'}
					intent={'primary'}
					onClick={() => {
						setStep(4);
					}}
				>
					Continuar
				</Button>
			</S.ButtonsContainer>
		</S.GeneralContainer>
	);
};

const SaveAccount = ({
	onConfirm,
	onCancel,
	isNextLoading,
}: {
	onConfirm: () => void;
	onCancel: () => void;
	isNextLoading: boolean;
}) => {
	return (
		<S.GeneralContainer>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2.4rem',
					alignItems: 'center',
				}}
			>
				<S.ModalIconContainer>
					<FaQuestion size={24} color={'white'} />
				</S.ModalIconContainer>
				<S.Description>
					<span>
						Notamos que este favorecido não está cadastrado na sua lista de
						favoritos,{' '}
					</span>
					<span className={'bold'}>gostaria de cadastrar?</span>
				</S.Description>
			</div>
			<S.ButtonsContainer>
				<Button
					roundness={'lg'}
					intent={'terciary'}
					$outline
					onClick={onCancel}
				>
					Não
				</Button>
				<Button
					roundness={'lg'}
					intent={'primary'}
					onClick={onConfirm}
					loading={isNextLoading}
				>
					Sim
				</Button>
			</S.ButtonsContainer>
		</S.GeneralContainer>
	);
};

const SaveSuccess = ({ onClose }: { onClose: () => void }) => {
	return (
		<S.GeneralContainer>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2.4rem',
					alignItems: 'center',
				}}
			>
				<S.ModalIconContainer>
					<FaCheck size={24} color={'white'} />
				</S.ModalIconContainer>
				<S.Description>
					<span>Favorecido salvo </span>
					<br />
					<span className={'bold'}>com sucesso</span>
				</S.Description>
			</div>
			<S.ButtonsContainer>
				<Button roundness={'lg'} intent={'primary'} onClick={onClose}>
					Fechar
				</Button>
			</S.ButtonsContainer>
		</S.GeneralContainer>
	);
};

export { LimitWarning, HourWarning, SaveAccount, SaveSuccess };
