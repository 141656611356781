import * as S from './styles';
import { Button } from '../../../../../componentsV2/ui/Button';
import { SelectField } from '../../../../../componentsV2/ui/Form/SelectField';
import {
	cnpjMask,
	cpfMask,
	onlyPositiveNumbersMask,
	parseMaskedCnpjToRaw,
	parseMaskedCPFToRaw,
} from '../../../../../utils/masks';
import React, { useEffect, useState } from 'react';
import {
	Beneficiary,
	BeneficiaryResponse,
} from '../../../../../@types/CorporateExpenses/Transfer';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	createBeneficiary,
	fetchBankList,
	getBeneficiaries,
	updateBeneficiary,
} from '../../../../../services/queries/Corpway/Transfers';
import { useAuth } from '../../../../../hooks/useAuth';
import Modal from '../../../../../componentsV2/ui/Modal';
import { FaCheck } from 'react-icons/fa';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { cnpj as cnpjValidator, cpf as cpfValidator } from 'cpf-cnpj-validator';
import { SearchableSelectField } from '../../../../../componentsV2/ui/Form/SearchableSelectField';

const initialState: Beneficiary = {
	name: '',
	document: '',
	bank_code: '',
	branch: '',
	account_number: '',
	type: undefined,
	account_type: undefined,
};

const beneficiarySchema = z
	.object({
		name: z.string().min(1, 'Nome do favorecido é obrigatório'),
		document: z.string().min(1, 'CPF/CNPJ é obrigatório'),
		bank_code: z
			.string()
			.min(3, 'Banco é obrigatório')
			.max(3, 'Código do banco deve ter 3 dígitos')
			.regex(/^\d+$/, 'O campo deve conter apenas números'),
		branch: z
			.string()
			.min(4, 'O código completo da agência é obrigatória')
			.max(4, 'Agência deve ter no máximo 4 dígitos')
			.regex(/^\d+$/, 'O campo deve conter apenas números'),
		check_digit: z
			.string()
			.trim()
			.min(1, 'O dígito verificador é obrigatório')
			.max(3, 'O dígito verificador pode ter no máximo 3 caracteres'),
		account_number: z
			.string()
			.min(2, 'O número completo da conta é obrigatória')
			.max(15, 'O número da conta deve ter no máximo 15 caracteres'),
		type: z.string().min(1, 'Tipo de favorecido é obrigatório'),
		account_type: z.string().min(1, 'Tipo de conta é obrigatório'),
	})
	.refine(
		({ document, type }) =>
			type === 'pf'
				? cpfValidator.isValid(document)
				: cnpjValidator.isValid(document),
		{
			path: ['document'],
			message: 'CPF/CNPJ inválido',
		}
	);

const UpdatedSuccessfully = ({ onClose }: { onClose: () => void }) => {
	return (
		<>
			<S.Title>Dados do favorecido:</S.Title>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2.4rem',
					alignItems: 'center',
				}}
			>
				<S.ModalIconContainer>
					<FaCheck size={24} color={'white'} />
				</S.ModalIconContainer>
				<S.Description>
					<span>Favorecido editado</span>
					<br />
					<span className={'bold'}>com sucesso</span>
				</S.Description>
			</div>
			<S.ButtonsContainer>
				<Button roundness={'lg'} intent={'primary'} onClick={onClose}>
					Fechar
				</Button>
			</S.ButtonsContainer>
		</>
	);
};

const CreatedSuccessfully = ({ onClose }: { onClose: () => void }) => {
	return (
		<>
			<S.Title>Dados do favorecido:</S.Title>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2.4rem',
					alignItems: 'center',
				}}
			>
				<S.ModalIconContainer>
					<FaCheck size={24} color={'white'} />
				</S.ModalIconContainer>
				<S.Description>
					<span>Favorecido criado</span>
					<br />
					<span className={'bold'}>com sucesso</span>
				</S.Description>
			</div>
			<S.ButtonsContainer>
				<Button roundness={'lg'} intent={'primary'} onClick={onClose}>
					Fechar
				</Button>
			</S.ButtonsContainer>
		</>
	);
};

const BeneficiaryForm = ({
	onClose,
	form,
	onSuccess,
	beneficiary,
}: {
	onClose: () => void;
	form: UseFormReturn<Beneficiary>;
	onSuccess: (beneficiary?: BeneficiaryResponse) => void;
	beneficiary?: BeneficiaryResponse;
}) => {
	const { currentCompany } = useAuth();

	const getBanksQuery = useQuery(['banks'], fetchBankList, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});
	const getBeneficiariesQuery = useQuery(
		['beneficiaries', currentCompany?.id],
		() => getBeneficiaries(currentCompany?.id!),
		{
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		}
	);

	const options =
		getBanksQuery.data?.map((bank) => {
			return {
				value: bank.code,
				label: bank.code + ' - ' + bank.name,
				filterValue: bank.name,
			};
		}) ?? [];

	const { register, formState, watch, handleSubmit, setValue, setError } = form;
	const type = watch('type');
	const bank_code = watch('bank_code');
	const document = watch('document');

	const createBeneficiaryMutation = useMutation(
		['createBeneficiary', currentCompany?.id],
		(data: Beneficiary) => createBeneficiary(currentCompany?.id!, data),
		{
			onSuccess: () => onSuccess(),
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível criar o favorecido.');
			},
		}
	);

	const updateBeneficiaryMutation = useMutation(
		['updateBeneficiary', beneficiary?.id],
		(data: Beneficiary) =>
			updateBeneficiary(beneficiary!.id, currentCompany!.id, data),
		{
			onSuccess: (data: BeneficiaryResponse) => onSuccess(data),
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível atualizar o favorecido.'
				);
			},
		}
	);

	function onCreate(data: Beneficiary) {
		data.document =
			type === 'pf'
				? parseMaskedCPFToRaw(data.document!)
				: parseMaskedCnpjToRaw(data.document!);
		const exists = getBeneficiariesQuery.data?.find((b) =>
			type === 'pf'
				? parseMaskedCPFToRaw(b.document) === data.document
				: parseMaskedCnpjToRaw(b.document) === data.document
		);
		if (exists && data.name?.toLowerCase() !== exists.name?.toLowerCase()) {
			setError('name', {
				message: `Favorecido ${exists.name} com mesmo documento e nome diferente já cadastrado`,
			});
			return;
		}

		data.bank_code = data.bank_code!.padStart(3, '0');
		data.account_number = data.account_number + '-' + data.check_digit;
		delete data.check_digit;
		createBeneficiaryMutation.mutate(data);
	}

	function onUpdate(data: Beneficiary) {
		data.document =
			type === 'pf'
				? parseMaskedCPFToRaw(data.document!)
				: parseMaskedCnpjToRaw(data.document!);
		data.bank_code = data.bank_code!.padStart(3, '0');
		data.account_number = data.account_number + '-' + data.check_digit;
		delete data.check_digit;
		updateBeneficiaryMutation.mutate(data);
	}

	return (
		<>
			<S.FormContainer style={{ paddingLeft: 0, paddingRight: 0 }}>
				<S.Title>{beneficiary ? 'Editar ' : 'Criar '} favorecido:</S.Title>
				<SelectField
					name={'type'}
					label={'Tipo de favorecido:'}
					register={register}
					autoComplete='off'
					placeholder={'Selecione uma opção'}
					onChange={(e) => {
						const value = e.target.value;
						setValue('type', value as 'pf' | 'pj');
						setValue(
							'document',
							value === 'pf'
								? cpfMask(document ?? '')
								: cnpjMask(document ?? '')
						);
					}}
					errorMessage={formState.errors.type?.message}
				>
					<option value={'pf'}>Pessoa física</option>
					<option value={'pj'}>Pessoa jurídica</option>
				</SelectField>

				{type && (
					<>
						<S.StyledInput
							name={'name'}
							register={register}
							autoComplete='off'
							label={type === 'pf' ? 'Nome completo:' : 'Razão social:'}
							placeholder={
								type === 'pf'
									? 'Nome completo do beneficiário'
									: 'Razão social da empresa'
							}
							errorMessage={formState.errors.name?.message}
						/>

						<S.StyledInput
							name={'document'}
							register={register}
							autoComplete='off'
							label={type === 'pf' ? 'CPF:' : 'CNPJ:'}
							placeholder={
								type === 'pf' ? '000.000.000-00' : '00.000.000/0000-00'
							}
							onChange={(e) => {
								const value = e.target.value;
								e.target.value =
									type === 'pf' ? cpfMask(value) : cnpjMask(value);
							}}
							errorMessage={formState.errors.document?.message}
						/>
					</>
				)}

				<SearchableSelectField
					placeholder={'Selecione o banco:'}
					name={'beneficiary.bank_code'}
					label={'Banco:'}
					autoComplete='off'
					value={bank_code}
					onItemSelect={(e) => setValue('bank_code', e)}
					options={options}
					errorMessage={formState.errors.bank_code?.message}
				/>

				<S.InputContainerRow>
					<S.StyledInput
						name={'branch'}
						label={'Agência:'}
						autoComplete='off'
						type={'tel'}
						onChange={(e) => {
							e.target.value = onlyPositiveNumbersMask(e.target.value, 4);
						}}
						register={register}
						placeholder={'0000'}
						errorMessage={formState.errors.branch?.message}
					/>

					<S.StyledInput
						name={'account_number'}
						type={'tel'}
						autoComplete='off'
						label={'Conta:'}
						register={register}
						placeholder={'00000'}
						onChange={(e) => {
							e.target.value = onlyPositiveNumbersMask(e.target.value, 15);
						}}
						errorMessage={formState.errors.account_number?.message}
					/>

					<S.InputDigitContainer>
						<S.StyledInput
							name={'check_digit'}
							type={'tel'}
							autoComplete='off'
							label={'Dígito:'}
							onChange={(e) => {
								e.target.value = onlyPositiveNumbersMask(e.target.value, 3);
							}}
							register={register}
							placeholder={'0'}
							errorMessage={formState.errors.check_digit?.message}
						/>
					</S.InputDigitContainer>
				</S.InputContainerRow>

				<SelectField
					name={'account_type'}
					label={'Tipo de conta:'}
					register={register}
					autoComplete='off'
					placeholder={'Selecione o tipo de conta'}
					errorMessage={formState.errors.account_type?.message}
				>
					<option value={'checking'}>Conta Corrente</option>
					<option value={'savings'}>Conta Poupança</option>
				</SelectField>
			</S.FormContainer>
			<S.ButtonsContainer>
				<Button roundness={'lg'} intent={'terciary'} $outline onClick={onClose}>
					Voltar
				</Button>
				<Button
					roundness={'lg'}
					intent={'primary'}
					loading={createBeneficiaryMutation.isLoading}
					onClick={
						beneficiary ? handleSubmit(onUpdate) : handleSubmit(onCreate)
					}
				>
					Salvar
				</Button>
			</S.ButtonsContainer>
		</>
	);
};

export default function NewBeneficiaryModal({
	beneficiary,
	onUpdate,
}: {
	beneficiary?: BeneficiaryResponse;
	onUpdate?: (beneficiary: BeneficiaryResponse) => void;
}) {
	const form = useForm<Beneficiary>({
		defaultValues: initialState,
		resolver: zodResolver(beneficiarySchema),
	});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [step, setStep] = useState<
		'create' | 'success_update' | 'success_create'
	>('create');
	const { reset, setValue } = form;
	const { currentCompany } = useAuth();

	const queryClient = useQueryClient();

	useEffect(() => {
		if (beneficiary) {
			setData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beneficiary, setValue]);

	function setData() {
		const beneficiaryCopy = { ...beneficiary };

		const document =
			beneficiaryCopy.type === 'pf'
				? cpfMask(beneficiaryCopy?.document ?? '')
				: cnpjMask(beneficiaryCopy?.document ?? '');
		const [account_number, check_digit] =
			beneficiaryCopy!.account_number!.split('-');

		setValue('name', beneficiaryCopy.name);
		setValue('document', document);
		setValue('bank_code', beneficiaryCopy.bank_code);
		setValue('branch', beneficiaryCopy.branch);
		setValue('account_number', account_number);
		setValue('check_digit', check_digit);
		setValue('type', beneficiaryCopy.type);
		setValue('account_type', beneficiaryCopy.account_type);
	}

	function resetForm() {
		setIsModalOpen(false);
		setStep('create');

		if (beneficiary) setData();
		else reset(initialState);
	}

	function resetToBeneficiaryDefaults() {
		setIsModalOpen(false);
		setStep('create');
		setData();
	}

	const onSuccess = (newBeneficiary?: BeneficiaryResponse) => {
		if (beneficiary) {
			setStep('success_update');
			if (onUpdate && newBeneficiary) onUpdate(newBeneficiary);
		} else {
			queryClient.invalidateQueries(['beneficiaries', currentCompany?.id]);
			setStep('success_create');
		}
	};

	const Form = () => {
		const Parts = {
			create: (
				<BeneficiaryForm
					onClose={beneficiary ? resetToBeneficiaryDefaults : resetForm}
					form={form}
					beneficiary={beneficiary}
					onSuccess={onSuccess}
				/>
			),
			success_create: <CreatedSuccessfully onClose={resetForm} />,
			success_update: <UpdatedSuccessfully onClose={resetForm} />,
		};

		return Parts[step];
	};
	return (
		<>
			{beneficiary ? (
				<Button onClick={() => setIsModalOpen(true)} intent={'link'}>
					Editar favorecido
				</Button>
			) : (
				<Button
					onClick={() => setIsModalOpen(true)}
					intent={'primary'}
					roundness={'lg'}
				>
					Cadastrar novo favorecido
				</Button>
			)}
			<Modal
				isOpen={isModalOpen}
				onRequestClose={beneficiary ? resetToBeneficiaryDefaults : resetForm}
			>
				<S.GeneralContainer style={{ width: '44rem' }}>
					<Form />
				</S.GeneralContainer>
			</Modal>
		</>
	);
}
