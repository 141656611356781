import React, { SelectHTMLAttributes } from 'react';
import styled from 'styled-components';
import { UseFormRegister } from 'react-hook-form';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
	label?: string | React.ReactNode;
	register?: UseFormRegister<any>;
	containerStyle?: React.CSSProperties;
	errorMessage?: string;
}

export default function SelectInput(props: Props) {
	return (
		<FieldSet style={props.containerStyle}>
			<Label htmlFor={props.name}>{props.label}</Label>
			<InputBase
				error={props.errorMessage}
				{...(props.register ? { ...props.register(props.name!) } : {})}
				{...props}
			>
				{props.children}
			</InputBase>
			{props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
		</FieldSet>
	);
}

const InputBase = styled.select<{ error?: string }>`
	width: 100%;
	height: 3rem;
	background-color: rgba(198, 198, 198, 0.33);
	border-radius: 0.4rem;
	border: 0.1rem solid var(--light-gray);
`;

const Label = styled.label`
	margin-bottom: 0.5rem;
	color: var(--dark-gray);
	font-weight: 600;
	position: relative;
`;

const FieldSet = styled.fieldset`
	border: none;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 0.5rem 1.2rem 0.5rem;
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
`;

const ErrorMessage = styled.span<{ error?: string }>`
	color: red;
	font-size: 1.2rem;
	margin-top: 0.5rem;
`;
