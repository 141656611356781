import { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Modal from '../Modal';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../ObrigatoryFieldsIndicator';
import * as S from './styles';
import * as FormStyle from '../Form/FormStyles';
import { useAuth } from '../../hooks/useAuth';
import { useMutation, useQueryClient } from 'react-query';
import {
	CostCenterCreationParams,
	createCostCenter,
} from '../../services/queries/CostCenters';
import { RiAddCircleLine } from 'react-icons/ri';
import { trimObjectData } from '../../utils/trimObjectData';
import { showErrorMessage } from '../../utils/ErrorHandler';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import InputField from '../../componentsV2/flexiblebenefits/ui/Form/InputField';
import TextAreaInput from '../../componentsV2/flexiblebenefits/ui/Form/TextAreaInput';

const formSchema = z.object({
	title: z
		.string()
		.min(1, { message: 'O campo título do centro deve ser preenchido' }),
	description: z
		.string()
		.min(1, { message: 'O campo de descrição deve ser preenchido' }),
});

interface CostCenterCreationModalProps {
	smallButtonStyle?: boolean;
}

export function CostCenterCreationModal({
	smallButtonStyle,
}: CostCenterCreationModalProps) {
	const { currentCompany } = useAuth();
	const [modalOpen, setModalOpen] = useState(false);
	const queryClient = useQueryClient();

	const createCostCenterQuery = useMutation(
		({ title, description }: CostCenterCreationParams) => {
			return createCostCenter(title, description, currentCompany?.id);
		},
		{
			onSuccess: () => {
				reset();
				queryClient.invalidateQueries('costCentersList');
				queryClient.invalidateQueries('costCenterSelect');
				toast.info('Centro de custo criado com sucesso!');
				setModalOpen(false);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível criar o centro de custo. '
				);
			},
		}
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: zodResolver(formSchema),
		defaultValues: {
			title: '',
			description: '',
		},
	});

	const onSubmit = (data: { title: string; description: string }) => {
		createCostCenterQuery.mutate(trimObjectData(data));
	};

	const buttonStyleAttrs: any = {
		smallStyle: !!smallButtonStyle,
	};
	if (smallButtonStyle)
		buttonStyleAttrs['data-rh'] = 'Adicionar centro de custo';

	return (
		<>
			<S.AddCostCenter
				onClick={(e) => {
					e.preventDefault();
					setModalOpen(true);
				}}
				{...buttonStyleAttrs}
			>
				{smallButtonStyle ? (
					<RiAddCircleLine data-testid='openButton_id' />
				) : (
					'Adicionar centro de custo'
				)}
			</S.AddCostCenter>

			<Modal
				isOpen={modalOpen}
				onRequestClose={() => {
					setModalOpen(false);
				}}
				enableClose
			>
				<FormStyle.FormContainer data-testid='cosCenterCreation-modal-container'>
					<InputField
						label={
							<span style={{ fontSize: '1.4em' }}>
								Título do centro de custo <ObrigatoryIndicator />
							</span>
						}
						register={register}
						name={'title'}
						data-testid='titleInput_test_id'
						errorMessage={errors.title?.message}
					/>

					<TextAreaInput
						label={
							<span style={{ fontSize: '2em' }}>
								Descrição <ObrigatoryIndicator />
							</span>
						}
						register={register}
						name={'description'}
						data-testid='descriptionInput_test_id'
						errorMessage={errors.description?.message}
					/>

					<ObrigatoryFieldsIndication />
					<FormStyle.ButtonsContainer>
						<FormStyle.FormButton
							type={'button'}
							onClick={handleSubmit(onSubmit)}
						>
							Salvar
						</FormStyle.FormButton>
						<S.CancelOption
							type={'button'}
							onClick={() => {
								setModalOpen(false);
							}}
						>
							Cancelar
						</S.CancelOption>
					</FormStyle.ButtonsContainer>
				</FormStyle.FormContainer>
			</Modal>
		</>
	);
}
