import React, { useState } from 'react';
import { Button } from '../../../../../componentsV2/ui/Button';
import * as S from './styles';
import Modal from '../../../../../componentsV2/ui/Modal';
import { FaQuestion } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { cancelTransfer } from '../../../../../services/queries/Corpway/Transfers';
import { useAuth } from '../../../../../hooks/useAuth';
import { CORPWAY_MANAGEMENT_KEY } from '../../../../../services/queries/Corpway/Management';

export default function CancelTransferModal({ id }: { id: string}) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { currentCompany, user } = useAuth();
	const queryClient = useQueryClient();

	const cancelTransferMutation = useMutation(
		['cancelTransaction'],
		() => cancelTransfer(id, currentCompany!.id),
		{
			onError: (error) => showErrorMessage(error as Error, 'Erro ao cancelar transferência'),
			onSuccess:  () => {
				queryClient.invalidateQueries([	CORPWAY_MANAGEMENT_KEY,
					user.id,
					currentCompany?.id,]
				)
			}
		},
	);

	return (
		<>
			<Button
				roundness={'lg'}
				style={{margin: "auto", marginTop: "40px"}}
				onClick={() => setIsModalOpen(true)}>
				Cancelar agendamento
			</Button>
			<Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
				<S.ModalHeader>
					Cancelar agendamento
				</S.ModalHeader>
				<S.GeneralContainer style={{ width: '44rem' }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '2.4rem',
							alignItems: 'center',
							maxWidth: '36rem',
						}}
					>
						<S.ModalIconContainer>
							<FaQuestion size={24} color={'white'} />
						</S.ModalIconContainer>
						<S.Description>
							<span>Tem certeza que deseja </span>
							<span className={'bold'}>cancelar </span>
							<br/>
							<span>esta transferência?</span>
						</S.Description>
					</div>
					<S.ButtonsContainer>
						<Button
							roundness={'lg'}
							intent={'terciary'}
							$outline
							onClick={() => {
								setIsModalOpen(false);
							}}
						>
							Voltar
						</Button>
						<Button
							roundness={'lg'}
							intent={'primary'}
							loading={cancelTransferMutation.isLoading}
							shrinkOnLoading={false}
							onClick={() => cancelTransferMutation.mutate()}
						>
							Cancelar
						</Button>
					</S.ButtonsContainer>
				</S.GeneralContainer>
			</Modal>
		</>
	);
}