import { SelectOption } from '..';

export const DeliveryAddressOptionsFilters: SelectOption[] = [
	{
		text: 'Colaboradores',
		value: 'collaborators',
	},
	{
		text: 'Empresa',
		value: 'company',
	},
	{
		text: 'Customizado',
		value: 'custom',
	},
];

export const MessageTypeOptionsFilters: SelectOption[] = [
	{
		text: 'Grupo Corporativo',
		value: 'group',
	},
	{
		text: 'Empresa',
		value: 'company',
	},
	{
		text: 'Customizado',
		value: 'custom',
	},
];

export const KYCStatusOptionsFilters: SelectOption[] = [
	{
		text: 'Aprovado',
		value: 'approved',
	},
	{
		text: 'Reprovado',
		value: 'reproved',
	},
	{
		text: 'Em análise',
		value: 'in_analysis',
	},
];

export const CollaboratorFormStatusOptionsFilters: SelectOption[] = [
	{
		text: 'Finalizado',
		value: 'completed',
	},
	{
		text: 'Não finalizado',
		value: 'incomplete',
	},
	{
		text: 'Sob análise',
		value: 'in_analysis',
	},
];

export const ContractTypeOptionsFilters: SelectOption[] = [
	{
		text: 'CLT',
		value: 'CLT',
	},
	{
		text: 'Estágio',
		value: 'TRAINEE',
	},
	{
		text: 'Sócio',
		value: 'SHAREHOLDER',
	},
	{
		text: 'PJ',
		value: 'PJ',
	},
];

export const MonthOptionsFilters: SelectOption[] = [
	{
		text: 'Janeiro',
		value: '1',
	},

	{
		text: 'Fevereiro',
		value: '2',
	},

	{
		text: 'Março',
		value: '3',
	},

	{
		text: 'Abril',
		value: '4',
	},

	{
		text: 'Maio',
		value: '5',
	},

	{
		text: 'Junho',
		value: '6',
	},

	{
		text: 'Julho',
		value: '7',
	},

	{
		text: 'Agosto',
		value: '8',
	},

	{
		text: 'Setembro',
		value: '9',
	},

	{
		text: 'Outubro',
		value: '10',
	},

	{
		text: 'Novembro',
		value: '11',
	},

	{
		text: 'Dezembro',
		value: '12',
	},
];

export const PaymentStatusOptionsFilters: SelectOption[] = [
	{ text: 'Cancelado', value: 'canceled' },
	{ text: 'Finalizado', value: 'approved' },
	{ text: 'Reprovado', value: 'disapproved' },
	{ text: 'Processando', value: 'processing' },
	{ text: 'Finalizado com erro', value: 'finished error' },
	{ text: 'Processando estorno', value: 'chargeback-processing' },
	{ text: 'Estornado', value: 'chargeback-success' },
	{ text: 'Estorno parcial', value: 'chargeback-partial' },
	{ text: 'Estornado com erro', value: 'chargeback-error' },
];

export const TransferTypeOptionsFilters: SelectOption[] = [
	{ text: 'Entrada', value: 'entrance' },
	{ text: 'Saída', value: 'exit' },
];

export const TransferCategoryOptionsFilters: SelectOption[] = [
	{ text: 'Pagamento de benefício', value: 'benefit' },
	{ text: 'Reembolso', value: 'refund' },
	{ text: 'Recebimento de recarga', value: 'balance_recharge_receipt' },
	{ text: 'Estorno de colaborador', value: 'collaborator_chargeback' },
	{ text: 'Estorno da empresa', value: 'company_chargeback' },
	{ text: 'Recebimento de chargeback', value: 'external_chargeback' },
	{ text: 'Recebimento de crédito inicial', value: 'first_credit_receipt' },
	{ text: 'Recebimento via Bounty', value: 'balance_addition_from_master' }
];

export const CollaboratorsWithCardsOptionsFilters: SelectOption[] = [
	{ text: 'Colaboradores sem cartão', value: 'no-card' },
	{ text: 'Colaboradores com cartão', value: 'card' },
	{ text: 'Colaboradores com cartão ativo', value: 'active-card' },
	{ text: 'Colaboradores com cartão solicitado', value: 'requested-card' },
	{ text: 'Colaboradores com cartão pendente', value: 'pending' },
	{ text: 'Colaboradores com cartão processando', value: 'processing' },
];

export const RefundStatusOptionsFilters: SelectOption[] = [
	{
		text: 'Aprovado',
		value: 'approved',
	},
	{
		text: 'Reprovado',
		value: 'canceled',
	},
];
