import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { UseFormRegister } from 'react-hook-form';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label?: string | React.ReactNode;
	register?: UseFormRegister<any>;
	containerStyle?: React.CSSProperties;
	errorMessage?: string;
}

export default function InputField(props: Props) {
	return (
		<FieldSet style={props.containerStyle}>
			<Label htmlFor={props.name}>{props.label}</Label>
			<InputBase
				error={props.errorMessage}
				{...(props.register ? { ...props.register(props.name!) } : {})}
				{...props}
			/>
			{props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
		</FieldSet>
	);
}

const InputBase = styled.input<{ error?: string }>`
	width: 100%;
	height: 3rem;
	color: var(--dark-gray);
	background-color: rgba(198, 198, 198, 0.33);
	border-radius: 0.4rem;
	border: none;
	padding: 0 1rem;

	&::placeholder {
		color: var(--mid-gray);
	}
`;

export const Label = styled.label`
	margin-bottom: 0.5rem;
	color: var(--dark-gray);
	font-weight: 600;
	position: relative;
`;

export const FieldSet = styled.fieldset`
	border: none;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 0.5rem 1.2rem 0.5rem;
	font-size: 1.4rem;
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
`;

export const ErrorMessage = styled.span<{ error?: string }>`
	color: red;
	font-size: 1.2rem;
	margin-top: 0.5rem;
`;
