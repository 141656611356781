import { FaInfoCircle } from 'react-icons/fa';
import * as S from './styles';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../hooks/useAuth';
import Modal from '../../../../../components/Modal';
import { getBenefitParsedTitle } from '../../../../../utils/benefits/getBenefitParsedTitle';
import { usePayment } from '../../../../../hooks/usePaymentV2';
import { calculateBenefitsTotals } from '../../utils/calculateBenefitsTotals';

interface PaymentSummaryProps {
	onSubmit(): void;
	closeSummary(): void;
	isOpenSummary: boolean;
	filterDisapproved?: boolean;
}

interface Benefit {
	title: string;
	totalValue: number;
}

export function PaymentSummary({
	onSubmit,
	isOpenSummary,
	closeSummary,
	filterDisapproved = false,
}: PaymentSummaryProps) {
	const { user, companyBalance } = useAuth();
	const { schedule, beneficiaries } = usePayment();
	const [benefits, setBenefits] = useState<Benefit[]>([]);
	const [totalValue, setTotalValue] = useState(0);

	useEffect(() => {
		if (!isOpenSummary) return;

		const totals = calculateBenefitsTotals(beneficiaries, filterDisapproved);

		setBenefits(totals.benefitsTotalsList.filter((b) => b.totalValue > 0));
		setTotalValue(totals.total);
	}, [isOpenSummary, filterDisapproved, beneficiaries]);

    const isFutureDate = (date: string | null | undefined): boolean => {
        if (typeof date !== 'string') return false

        const parsedDate = new Date(date)
        return !isNaN(parsedDate.getTime()) && parsedDate > new Date()
    }

	function handleSubmit() {
		closeSummary();

		if (user.access_level === 'operator') {
			onSubmit();
		} else {
			// an admin
			if (companyBalance.multiflex_balance >= totalValue) {
				// there is enough balance to release the payment
				onSubmit();
			} else if (isFutureDate(schedule)) {
				onSubmit();

				toast.warning(
					'Atualmente o saldo da empresa é insuficiente para efetivar o pagamento agendado. Requisite uma transferência antes da data do agendamento para que o lançamento ocorra com sucesso.',
					{
						autoClose: 50000,
						position: 'bottom-right',
					}
				);
			} else {
				toast.error('Saldo insuficiente para realizar o lançamento');
			}
		}
	}

	function getCollaboratorsTotal() {
		let collabTotal = 0;
		beneficiaries.forEach((beneficiary) => {
			if (
				beneficiary.selected &&
				!beneficiary.benefits.every((b) => !!b.reason_disapproval)
			) {
				if (beneficiary.isGroup) {
					collabTotal += Number(beneficiary.collaboratorsAmount);
				} else {
					collabTotal += 1;
				}
			}
		});

		return collabTotal;
	}

	return (
		<Modal isOpen={isOpenSummary} onRequestClose={closeSummary} enableClose>
			<S.Container>
				<S.Header>
					<FaInfoCircle />
					<span>Resumo da solicitação</span>
				</S.Header>

				<S.BenefitsList>
					{benefits.map((benefit) => (
						<S.BenefitContainer key={benefit.title}>
							<S.InfoTitle>{getBenefitParsedTitle(benefit.title)}</S.InfoTitle>
							<S.InfoValue data-testid={`${benefit.title}-value`}>
								R$ {benefit.totalValue.toLocaleString()}
							</S.InfoValue>
						</S.BenefitContainer>
					))}
				</S.BenefitsList>

				<S.InfoContainer>
					<S.InfoTitle>Valor total</S.InfoTitle>
					<S.InfoValue>R$ {totalValue.toLocaleString()}</S.InfoValue>
				</S.InfoContainer>
				<S.InfoContainer>
					<S.InfoTitle>Colaboradores beneficiados</S.InfoTitle>
					<S.InfoValue data-testid='beneficiaries-amount'>
						{getCollaboratorsTotal()}
					</S.InfoValue>
				</S.InfoContainer>
				{schedule && (
					<S.InfoContainer>
						<S.InfoTitle>Data de agendamento</S.InfoTitle>
						<S.InfoValue>{new Date(schedule).toLocaleString()}</S.InfoValue>
					</S.InfoContainer>
				)}
				<S.OptionsContainer>
					<S.SecondOption onClick={closeSummary}>Cancelar</S.SecondOption>
					<S.MainOption onClick={handleSubmit}>Confirmar</S.MainOption>
				</S.OptionsContainer>
			</S.Container>
		</Modal>
	);
}
