import { Props } from '..';
import { Typography } from '../../../../../../componentsV2/ui/Typography';
import { formatCardNumber } from '../../../../../../utils/formatCardNumber';
import {
	parseMovementStatus,
	parseMovementType,
} from '../../../../Management/utils/parseBalanceMovements';
import * as S from '../styles';
import { cnpjMask, cpfMask } from '../../../../../../utils/masks';
import CancelTransferModal from '../../../Transfers/components/CancelTransferModal';
import Destiny from '../../../../../../assets/corpwayExtractReport/destiny-arrow.svg';

export function MainContent({ movement }: Props) {
	const movementType = parseMovementType(movement.type, movement.action);

	if (
		movementType === 'Estorno para carteira' ||
		movementType === 'Repasse para cartão'
	) {
		return (
			<>
				<S.SubTitleRow>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Detalhes do cartão de{' '}
						{movementType === 'Estorno para carteira' ? 'origem' : 'destino'}:
					</Typography>
				</S.SubTitleRow>

				<S.FieldsContainer>
					<S.Field>
						<Typography color='var(--dark-gray)'>Responsável:</Typography>

						<Typography color='var(--dark-gray)'>
							{movement.card?.responsible}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Nome do cartão:</Typography>
						<Typography color='var(--dark-gray)'>
							{movement.card?.alias}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Número do cartão:</Typography>
						<Typography color='var(--dark-gray)'>
							{formatCardNumber(movement.card?.pan!)}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Centro de custo:</Typography>
						<Typography color='var(--dark-gray)'>
							{movement.card?.cost_center}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Data da operação:</Typography>
						<Typography color='var(--dark-gray)'>
							{Intl.DateTimeFormat('pt-BR', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
								hour12: false, // 24-hour format
							})
								.format(new Date(movement.created_at))
								.replace(',', ' às')}
						</Typography>
					</S.Field>
				</S.FieldsContainer>
				<S.SubTitleRow style={{ marginTop: '25px' }}>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Status da transação:
					</Typography>

					{parseMovementStatus(movement.status!)}
				</S.SubTitleRow>
			</>
		);
	}

	if (movementType === 'Recarga de carteira') {
		return (
			<>
				<S.SubTitleRow>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Detalhes da transação:
					</Typography>
				</S.SubTitleRow>

				<S.FieldsContainer>
					<S.Field>
						<Typography color='var(--dark-gray)'>Responsável:</Typography>

						<Typography color='var(--dark-gray)'>
							{movement.operator?.name}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Operação via:</Typography>
						<Typography color='var(--dark-gray)'>
							{movement.type === 'pix' ? 'Pix' : 'Boleto'}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Destino:</Typography>
						<Typography color='var(--dark-gray)'>Carteira</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Data da operação:</Typography>
						<Typography color='var(--dark-gray)'>
							{Intl.DateTimeFormat('pt-BR', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
								hour12: false, // 24-hour format
							})
								.format(new Date(movement.created_at))
								.replace(',', ' às')}
						</Typography>
					</S.Field>
				</S.FieldsContainer>
				<S.SubTitleRow style={{ marginTop: '25px' }}>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Status da transação:
					</Typography>

					{parseMovementStatus(movement.status!)}
				</S.SubTitleRow>
			</>
		);
	}

	if (movementType === 'Transferência externa') {
		const shortName = movement.beneficiary?.name
			?.split(' ')
			.map((word, index, arr) => {
				if (index === 0 || index === arr.length - 1) return word;
				return word[0];
			})
			.join(' ');
		return (
			<>
				<S.InfoHeader>
					<img src={Destiny} alt='' />
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Destino:
					</Typography>
				</S.InfoHeader>

				<S.FieldsContainer style={{ marginTop: "1rem", gap: "0.6rem" }}>
					<S.Field>
						<Typography color='var(--dark-gray)' style={{ whiteSpace: "nowrap" }}>
							{
								movement.beneficiary?.type === 'pf' ? 'Nome completo:' : 'Razão social:'
							}
						</Typography>

						<Typography color='var(--dark-gray)' style={{ textAlign: "end" }}>
							{movement.beneficiary?.name && movement.beneficiary?.name.length > 20 ? shortName : movement.beneficiary?.name}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>
							{
								movement.beneficiary?.type === 'pf' ? 'CPF:' : 'CNPJ:'
							}
						</Typography>
						<Typography color='var(--dark-gray)'>
							{
								movement.beneficiary?.type === 'pf' ?
									cpfMask(movement.beneficiary!.document) : cnpjMask(movement.beneficiary!.document)
							}
						</Typography>
					</S.Field>

					<S.Field>
						<Typography color='var(--dark-gray)'>Instituição:</Typography>

						<Typography color='var(--dark-gray)'>
							{movement.beneficiary?.bank_code} - {movement.beneficiary?.bank_name}
						</Typography>
					</S.Field>


					<S.Field>
						<Typography color='var(--dark-gray)'>
							Agência:
						</Typography>
						<Typography color='var(--dark-gray)'>
							{movement.beneficiary?.branch}
						</Typography>
					</S.Field>
					<S.Field>
						<Typography color='var(--dark-gray)'>
							Conta:
						</Typography>
						<Typography color='var(--dark-gray)'>
							{movement.beneficiary?.account_number}
						</Typography>
					</S.Field>
					<S.Field>
						<Typography color='var(--dark-gray)'>
							Tipo de conta:
						</Typography>
						<Typography color='var(--dark-gray)'>
							{
								movement.beneficiary?.account_type === 'checking' ? 'Conta corrente' : 'Conta poupança'
							}
						</Typography>
					</S.Field>
					<S.Field>
						<Typography color='var(--dark-gray)'>
							Data da operação:
						</Typography>
						<Typography color='var(--dark-gray)'>
							{Intl.DateTimeFormat('pt-BR', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
								hour12: false, // 24-hour format
							})
								.format(new Date(movement.created_at))
								.replace(',', ' às')}
						</Typography>
					</S.Field>
					{
						movement.scheduled_date && (
							<S.Field>
								<Typography color='var(--dark-gray)'>
									Data agendada:
								</Typography>
								<Typography color='var(--dark-gray)'>
									{Intl.DateTimeFormat('pt-BR', {
										timeZone: 'UTC',
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
									}).format(new Date(movement.scheduled_date))
									}
								</Typography>
							</S.Field>
						)
					}
				</S.FieldsContainer>

				<S.SubTitleRow style={{ marginTop: '32px' }}>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Status da transação:
					</Typography>

					{parseMovementStatus(movement.status!)}
				</S.SubTitleRow>

				{
					(movement.status === 'scheduled' || movement.status === 'pending') &&
					<S.MessageContainer style={{ marginTop: '20px' }}>
						<Typography style={{ textAlign: "center" }} size={"1.2rem"} color="var(--dark-gray)">
							<p>
								Compensação prevista em <b>até 2 dias úteis </b>  {
									movement.scheduled_date && movement.status === 'scheduled' ? "à partir da efetivação do agendamento." : "."
								}
							</p>
						</Typography>
					</S.MessageContainer>
				}

				{movement.status === 'error' &&
					<S.MessageContainer style={{ marginTop: '20px' }}>
						<Typography style={{ textAlign: "center" }} size={"1.2rem"} color="var(--dark-gray)">
							<p>
								<b>Motivo:</b> {movement.error_msg}

							</p>
						</Typography>
					</S.MessageContainer>
				}

				{
					movement.scheduled_date && movement.status === 'scheduled' && (
						<CancelTransferModal id={movement.id} />
					)
				}
			</>
		);
	}

	if (movementType === 'Receb. via chargeback') {
		return (
			<>
				<S.SubTitleRow>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Detalhes do{' '}
						{movementType}
					</Typography>
				</S.SubTitleRow>

				<S.FieldsContainer>
					{movement.card && (
						<>
							<S.Field>
								<Typography color='var(--dark-gray)'>Responsável:</Typography>

								<Typography color='var(--dark-gray)'>
									{movement.card?.responsible}
								</Typography>
							</S.Field>

							<S.Field>
								<Typography color='var(--dark-gray)'>Nome do cartão:</Typography>
								<Typography color='var(--dark-gray)'>
									{movement.card?.alias}
								</Typography>
							</S.Field>

							<S.Field>
								<Typography color='var(--dark-gray)'>Número do cartão:</Typography>
								<Typography color='var(--dark-gray)'>
									{formatCardNumber(movement.card?.pan!)}
								</Typography>
							</S.Field>

							<S.Field>
								<Typography color='var(--dark-gray)'>Centro de custo:</Typography>
								<Typography color='var(--dark-gray)'>
									{movement.card?.cost_center}
								</Typography>
							</S.Field>
						</>
					)}

					<S.Field>
						<Typography color='var(--dark-gray)'>Data da operação:</Typography>
						<Typography color='var(--dark-gray)'>
							{Intl.DateTimeFormat('pt-BR', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
								hour12: false, // 24-hour format
							})
								.format(new Date(movement.updated_at))
								.replace(',', ' às')}
						</Typography>
					</S.Field>
					<S.Field style={{
						flexDirection: 'column'
					}}>
						<Typography color='var(--dark-gray)'>Descrição:</Typography>

						<Typography color='var(--dark-gray)'>
							{movement.description}
						</Typography>
					</S.Field>
				</S.FieldsContainer>
				<S.SubTitleRow style={{ marginTop: '25px' }}>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Status da transação:
					</Typography>

					{parseMovementStatus(movement.status!)}
				</S.SubTitleRow>
			</>
		);
	}
	if (movementType === 'Crédito inicial') {
		return (
			<>
				<S.SubTitleRow>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Detalhes do{' '}
						{movementType}
					</Typography>
				</S.SubTitleRow>

				<S.FieldsContainer>

					<S.Field>
						<Typography color='var(--dark-gray)'>Data da operação:</Typography>
						<Typography color='var(--dark-gray)'>
							{Intl.DateTimeFormat('pt-BR', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
								hour12: false, // 24-hour format
							})
								.format(new Date(movement.updated_at))
								.replace(',', ' às')}
						</Typography>
					</S.Field>
					<S.Field style={{
						flexDirection: 'column'
					}}>
						<Typography color='var(--dark-gray)'>Descrição:</Typography>

						<Typography color='var(--dark-gray)'>
							{movement.description}
						</Typography>
					</S.Field>

				</S.FieldsContainer>
				<S.SubTitleRow style={{ marginTop: '25px' }}>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Status da transação:
					</Typography>

					{parseMovementStatus(movement.status!)}
				</S.SubTitleRow>
			</>
		);
	}
	if (movementType === 'Remoção de saldo' || 'Adição de saldo') {
		return (
			<>
				<S.SubTitleRow>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Detalhes do{' '}
						{movementType}
					</Typography>
				</S.SubTitleRow>

				<S.FieldsContainer>

					<S.Field>
						<Typography color='var(--dark-gray)'>Data da operação:</Typography>
						<Typography color='var(--dark-gray)'>
							{Intl.DateTimeFormat('pt-BR', {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
								hour12: false, // 24-hour format
							})
								.format(new Date(movement.created_at))
								.replace(',', ' às')}
						</Typography>
					</S.Field>
					<S.Field>
						<Typography color='var(--dark-gray)'>Responsável:</Typography>

						<Typography color='var(--dark-gray)'>
							Bounty
						</Typography>
					</S.Field>

				</S.FieldsContainer>
				<S.SubTitleRow style={{ marginTop: '25px' }}>
					<Typography size='1.6rem' weight='600' color='var(--dark-gray)'>
						Status da transação:
					</Typography>

					{parseMovementStatus(movement.status!)}
				</S.SubTitleRow>
			</>
		)
	}

	return <></>;
}
