import { useForm } from 'react-hook-form';
import {
	Collaborator,
	CollaboratorUnderageDocument,
	Group,
	UnderageResponsible,
} from '../../@types';
import { GroupSelector } from '../GroupSelector';
import { cepMask, cpfMask, phoneMask } from '../../utils/masks';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../ObrigatoryFieldsIndicator';
import * as FormStyle from '../Form/FormStyles';
import { KYCFieldsIndication, KYCFieldsIndicator } from '../KYCFieldsIndicator';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';
import { subtractYears } from '../../utils/parseDate';
import { useQuery } from 'react-query';
import { getCostCentersSelect } from '../../services/queries/CostCenters';
import { CostCenterCreationModal } from '../CostCenterCreationModal';
import { useEffect, useState } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { UF_List } from '../../utils/CheckUF';
import * as S from './styles';
import {
	FaCheckCircle,
	FaExclamationCircle,
	FaQuestionCircle,
	FaRegClock,
} from 'react-icons/fa';
import PreventTransitionPrompt from '../PreventTransitionPrompt';
import { UnderageResponsibleModal } from './UnderageResponsibleModal';
import {
	addressFieldsSWAPRegex,
	cepRegex,
	phoneRegex,
} from '../../utils/patterns';
import { trimObjectData } from '../../utils/trimObjectData';
import { showErrorMessage } from '../../utils/ErrorHandler';
import IconHover from '../IconHover';
import { warningOnlyNumbersComplementMessage } from '../../utils/validateCollaborator';
import { useGetAddressByCEP } from '../../hooks/useGetAddressByCEP';
import { SearchCEPButton } from '../SearchCEPButton';
import { ManageBenefitsModal } from '../../pages/FlexibleBenefits/Collaborators/ManageCollaborators/ManageBenefitsModal';
import { AlertValidEmail, AlertValidEmailText } from '../AlertValidEmail';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import InputField, {
	ErrorMessage,
} from '../../componentsV2/flexiblebenefits/ui/Form/InputField';
import SelectInput from '../../componentsV2/flexiblebenefits/ui/Form/SelectInput';

const schema = z.object({
	last_name: z
		.string()
		.min(1, 'O campo sobrenome deve ser preenchido')
		.max(30, 'O campo sobrenome deve ter no máximo 30 caracteres')
		.regex(
			/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/,
			'O campo sobrenome não pode conter números'
		),
	first_name: z
		.string()
		.min(1, 'O campo nome deve ser preenchido')
		.max(30, 'O campo nome deve ter no máximo 30 caracteres')
		.regex(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/, 'O campo nome não pode conter números'),
	mother_name: z
		.string()
		.min(1, 'O campo nome da mãe deve ser preenchido')
		.max(255, 'O campo nome da mãe deve ter no máximo 255 caracteres')
		.regex(
			/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/,
			'O campo nome da mãe não pode conter números'
		),
	cpf: z
		.string()
		.min(11, 'O CPF deve ter exatamente 11 dígitos')
		.transform((value) => cpfMask(value)),
	birth_date: z.string().min(1, 'A data de nascimento deve ser preenchida'),
	office: z.string().min(1, 'O campo cargo deve ser preenchido'),
	section: z.string().min(1, 'O campo setor de atuação deve ser preenchido'),
	branch_office: z.string().optional(),
	id_cost_center: z
		.string()
		.min(1, 'Selecione um centro de custo')
		.refine((value) => value !== '', 'Selecione um centro de custo'),
	address: z
		.string()
		.min(1, 'O campo de endereço deve ser preenchido')
		.max(50, 'O campo de endereço deve ter no máximo 50 caracteres')
		.regex(
			addressFieldsSWAPRegex,
			"Utilize apenas letras, espaços e (.) (,), ('), (/), (-) no campo de endereço"
		),
	number: z
		.string()
		.max(10, 'O número deve ter no máximo 10 caracteres')
		.refine(
			(value) => value === 'Sem número' || /^\d+$/.test(value),
			'O número deve ser um valor numérico ou "Sem número"'
		)
		.refine(
			(value) => value === 'Sem número' || value.length > 0,
			'O campo de número deve ser preenchido'
		),
	district: z
		.string()
		.min(1, 'O campo bairro deve ser preenchido')
		.max(30, 'O campo bairro deve ter no máximo 30 caracteres')
		.regex(
			addressFieldsSWAPRegex,
			"Utilize apenas letras, espaços e (.) (,), ('), (/), (-) no campo de Bairro"
		),
	cep: z
		.string()
		.min(8, 'O CEP deve ter exatamente 8 dígitos')
		.regex(cepRegex, 'Insira um CEP válido')
		.transform((value) => cepMask(value)),
	city: z
		.string()
		.min(1, 'O campo de endereço deve ser preenchido')
		.max(30, 'O campo cidade deve ter no máximo 30 caracteres')
		.regex(
			/^[a-zA-ZÀ-ÿ\s]+$/,
			'Utilize apenas letras e espaços no campo de cidade'
		),
	complement: z
		.string()
		.max(30, 'O campo complemento deve ter no máximo 30 caracteres')
		.optional()
		.refine(
			(value) =>
				value === undefined || value === '' || /^[a-zA-ZÀ-ÿ\s]+$/.test(value),
			'Utilize apenas letras e espaços no campo de complemento'
		),
	reference: z
		.string()
		.max(30, 'O campo referência deve ter no máximo 30 caracteres')
		.optional(),
	uf: z.string().min(1, 'Selecione uma UF'),
	email: z
		.string()
		.min(1, 'O campo de email deve ser preenchido')
		.email('Insira um email válido'),
	first_phone: z
		.string()
		.min(1, 'O campo de telefone deve ser preenchido')
		.regex(phoneRegex, 'Insira o telefone no formato +55 XX XXXXX-XXXX'),
	second_phone: z
		.string()
		.regex(phoneRegex, 'Insira o telefone no formato +55 XX XXXXX-XXXX')
		.or(z.literal('')),
	contract_type: z.enum(['CLT', 'TRAINEE', 'SHAREHOLDER', 'PJ'], {
		invalid_type_error: 'Selecione um tipo de contrato válido',
	}),
	underage_document: z
		.string()
		.optional()
		.refine(
			(value) => !value || /^[A-Za-z0-9\s-]+$/.test(value),
			'Insira um documento'
		),
});

type FormData = {
	last_name: string;
	cpf?: string;
	mother_name?: string;
	complement?: string;
	email?: /*unresolved*/ any;
	city?: string;
	district?: string;
	reference?: string;
	address?: string;
	branch_office?: string;
	section?: string;
	first_name?: string;
	cep?: /*unresolved*/ any;
	first_phone?: string;
	second_phone?: string;
	birth_date?: string;
	id_cost_center?: string;
	uf?: string;
	number?: string;
	office?: string;
	contract_type: string;
	underage_document?: string;
};

export interface CollaboratorFormProps {
	collaborator?: Collaborator;

	onSubmit(data: Collaborator): Promise<void>;

	changeStatus?(): Promise<void>;

	addedGroups?: Group[];

	addCollabToTheGroups?(groups: Group[]): void;

	removeCollabFromGroups?(groups: Group[]): void;

	onDelete?(): void;
}

const CollaboratorForm = ({
	collaborator,
	onSubmit,
	changeStatus,
	addedGroups,
	addCollabToTheGroups,
	removeCollabFromGroups,
	onDelete,
}: CollaboratorFormProps) => {
	const { currentCompany } = useAuth();

	const [hasNoNumber, setHasNoNumber] = useState(false);
	const [isUnderage, setIsUnderage] = useState(!!collaborator?.document);
	const [underageDocument, setUnderageDocument] = useState<File | null>(null);
	const [hasDifferentEmailDomain, setHasDifferentEmailDomain] = useState(false);

	const [underageResponsible, setUnderageResponsible] =
		useState<UnderageResponsible | null>(null);

	const {
		register,
		handleSubmit,
		reset,
		getValues,
		formState: { errors, isDirty },
		setValue,
	} = useForm<FormData>({
		resolver: zodResolver(schema),
		defaultValues: {
			...collaborator,
			first_name: collaborator?.first_name,
			last_name: collaborator?.last_name,
			cpf: cpfMask(collaborator?.cpf || ''),
			mother_name: collaborator?.mother_name,
			complement: collaborator?.complement,
			email: collaborator?.email,
			city: collaborator?.city,
			district: collaborator?.district,
			reference: collaborator?.reference,
			address: collaborator?.address,
			branch_office: collaborator?.branch_office,
			section: collaborator?.section,
			cep: cepMask(collaborator?.cep || ''),
			first_phone: phoneMask(collaborator?.first_phone || ''),
			second_phone: phoneMask(collaborator?.second_phone || ''),
			birth_date: parseDateToInput(collaborator?.birth_date),
			id_cost_center: collaborator?.cost_center?.id || '',
			uf: collaborator?.uf,
			number: collaborator?.number,
			office: collaborator?.office,
			contract_type: collaborator?.contract_type,
		},
	});

	useEffect(() => {
		reset({
			...collaborator,
			cpf: cpfMask(collaborator?.cpf || ''),
			cep: cepMask(collaborator?.cep || ''),
			first_phone: phoneMask(collaborator?.first_phone || ''),
			second_phone: phoneMask(collaborator?.second_phone || ''),
			birth_date: parseDateToInput(collaborator?.birth_date),
			id_cost_center: collaborator?.cost_center?.id || '',
			uf: collaborator?.uf || '',
		});

		checkUnderageInputChange(parseDateToInput(collaborator?.birth_date));

		if (collaborator?.number === 'Sem número') {
			setHasNoNumber(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collaborator]);

	const fetchCostCentersQuery = useQuery(
		['costCenterSelect', currentCompany?.id],
		() => {
			return getCostCentersSelect(currentCompany?.id);
		},
		{
			onSuccess: () => {
				reset({ ...getValues() });
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um erro ao buscar os centros de custo. '
				);
			},
			refetchOnWindowFocus: false,
		}
	);

	const { searchAddressByCEP, isSearchingCEP } = useGetAddressByCEP({
		onAddressFoundCallback: (address) => {
			toast.info(
				'Verifique os campos de endereço antes de terminar o cadastro, pois serão os dados utilizados para o envio do cartão!'
			);
			reset({ ...getValues(), ...address }); // reset the form filling with the fetched address
		},
	});

	async function handleSubmitForm(data: Collaborator) {
		if (isUnderage) {
			if (underageDocument) data.document = underageDocument;

			if (!underageResponsible && !collaborator?.responsible) {
				toast.error(
					'Preencha e salve as informações da pessoa responsável pelo colaborador.'
				);
				return;
			}

			if (underageResponsible) data.responsible = underageResponsible;
		}

		await onSubmit(trimObjectData(data)); // Send to the parent (AddCollaborator Page or EditCollaborator)
	}

	function checkUnderageInputChange(input: string) {
		const today = new Date();
		if (!(Number(input.slice(0, 4)) > 1900)) {
			setIsUnderage(false);
			return;
		}

		const inputDate = new Date(input.replaceAll('-', '/'));
		let eighteenYearsAgo = today;
		eighteenYearsAgo.setFullYear(today.getFullYear() - 18);

		// check if collaborator is underage
		if (inputDate.valueOf() > eighteenYearsAgo.valueOf()) {
			setIsUnderage(true);
		} else {
			setIsUnderage(false);
		}
	}

	function checkEmailDomain(email: string) {
		if (email === '' || email.indexOf('@') === -1) {
			setHasDifferentEmailDomain(false);
			return;
		}

		const email_domain = email.substring(email.indexOf('@'), email.length);

		if (email_domain !== currentCompany?.email_domain) {
			setHasDifferentEmailDomain(true);
		} else {
			setHasDifferentEmailDomain(false);
		}
	}

	function checkNoNumber() {
		setHasNoNumber(!hasNoNumber);

		setValue('number', 'Sem número');
	}

	function parseDateToInput(date?: string) {
		if (!date) return '';

		const length = date.length;
		try {
			return `${date.substring(length, length - 4)}-${date.substring(
				length - 5,
				length - 7
			)}-${date.substring(0, 2)}`;
		} catch (err) {
			return date;
		}
	}

	function isDocumentEditable(document?: File | CollaboratorUnderageDocument) {
		if (!document) return true;
		return (document as CollaboratorUnderageDocument).status !== 'approved';
		// return document instanceof File || (document as CollaboratorUnderageDocument).status !== "approved";
	}

	function getDocumentStatusIcon(status: string) {
		if (status === 'approved') return <FaCheckCircle color='#1CCA0D' />;

		if (status === 'disapproved')
			// return   <FaTimesCircle color="#000"/>
			return <FaExclamationCircle color='#FB1313' />;

		if (status === 'requested') return <FaRegClock color='#E0E414' />;

		return <FaQuestionCircle />;
	}

	function parseDocumentStatus(status: string) {
		if (status === 'approved') return 'Aprovado';
		if (status === 'disapproved') return 'Desaprovado';
		if (status === 'requested') return 'Em análise';
	}

	function getResponsibleModalButtonStyle() {
		return (
			!!collaborator?.document && isDocumentEditable(collaborator?.document)
		);
	}

	return (
		<FormStyle.Form
			onSubmit={(e) => {
				e.preventDefault();
				handleSubmit(handleSubmitForm)();
				e.stopPropagation();
			}}
			data-testid='collaborator_form_test_id'
		>
			{isDirty && <PreventTransitionPrompt />}

			<FormStyle.FormContainer>
				{/* COMPANY DATA FIELDSET */}
				<FormStyle.FieldSet>
					<FormStyle.FieldGroup>
						<InputField
							name={'first_name'}
							register={register}
							label={
								<>
									Primeiro nome <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</>
							}
							data-testid='firstNameInput_test_id'
							maxLength={30}
							errorMessage={errors.first_name?.message}
						/>

						<InputField
							name={'last_name'}
							register={register}
							label={
								<>
									Sobrenome completo <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</>
							}
							data-testid='lastNameInput_test_id'
							maxLength={30}
							errorMessage={errors.last_name?.message}
						/>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<InputField
							name={'mother_name'}
							register={register}
							label={
								<>
									Nome da mãe completo <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</>
							}
							data-testid='motherNameInput_test_id'
							maxLength={255}
							errorMessage={errors.mother_name?.message}
						/>

						<InputField
							name={'cpf'}
							register={register}
							label={
								<>
									CPF <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</>
							}
							placeholder='Ex: 999.999.999-99'
							disabled={!!collaborator?.cpf}
							data-testid='cpfInput_test_id'
							onChange={(event) => {
								const { value } = event.target;
								setValue('cpf', cpfMask(value), { shouldDirty: true });
							}}
							errorMessage={errors.cpf?.message}
						/>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<InputField
							type={'date'}
							name={'birth_date'}
							register={register}
							label={
								<>
									Data de nascimento <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</>
							}
							containerStyle={{ maxWidth: '25%' }}
							max={subtractYears(new Date(), 10).toISOString().split('T')[0]}
							min='1900-01-01'
							data-testid='birthInput_test_id'
							onChange={(e) => {
								checkUnderageInputChange(e.target.value);
								setValue('birth_date', e.target.value, {
									shouldDirty: true,
								});
							}}
							errorMessage={errors.birth_date?.message}
						/>
						{isUnderage && (
							<FormStyle.Field>
								<FormStyle.Label htmlFor='underage_document'>
									Documento para menores de idade <ObrigatoryIndicator />
									<KYCFieldsIndicator />
									<IconHover
										Icon={AiOutlineQuestionCircle}
										textHover='Colaboradores menores de idade precisam do documento de autorização dos responsáveis'
										sizeIcon='1.4rem'
									/>
									{errors.underage_document && (
										<ErrorMessage>
											{errors.underage_document.message}
										</ErrorMessage>
									)}
								</FormStyle.Label>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									{collaborator?.document && (
										<S.DocumentContainer>
											<FormStyle.DocumentLink
												href={
													(
														collaborator.document as CollaboratorUnderageDocument
													).url
												}
												target='_blank'
												rel='noopener noreferrer'
												download
											>
												Documento enviado
											</FormStyle.DocumentLink>
											<S.DocumentStatusContainer>
												{getDocumentStatusIcon(
													(
														collaborator.document as CollaboratorUnderageDocument
													).status
												)}
												{parseDocumentStatus(
													(
														collaborator.document as CollaboratorUnderageDocument
													).status
												)}
											</S.DocumentStatusContainer>
										</S.DocumentContainer>
									)}
									{isDocumentEditable(collaborator?.document) && (
										<>
											<FormStyle.Input
												type='file'
												style={{
													padding: '0.4rem',
													width: '50%',
													height: 'fit-content',
												}}
												accept='application/pdf'
												multiple={false}
												onChange={(e) =>
													setUnderageDocument(
														e.target.files ? e.target.files[0] : null
													)
												}
												name='underage_document'
												data-testid='documentInput_test_id'
												required={!collaborator?.document}
											/>
											<S.ModelLink
												href={`${window.location.origin}/Modelo_Termo_Consentimento.docx`}
												download
											>
												Baixar modelo
											</S.ModelLink>
										</>
									)}

									<UnderageResponsibleModal
										responsible={collaborator?.responsible}
										onSubmit={(responsible) =>
											setUnderageResponsible(responsible)
										}
										smallButton={getResponsibleModalButtonStyle()}
										editable={isDocumentEditable(collaborator?.document)}
									/>
								</div>
							</FormStyle.Field>
						)}
					</FormStyle.FieldGroup>

					<FormStyle.Divider />

					<FormStyle.FieldGroup>
						<InputField
							name={'office'}
							register={register}
							label={
								<>
									Cargo <ObrigatoryIndicator />{' '}
								</>
							}
							data-testid='officeInput_test_id'
							errorMessage={errors.office?.message}
						/>

						<InputField
							name={'section'}
							register={register}
							label={
								<>
									Setor de atuação <ObrigatoryIndicator />{' '}
								</>
							}
							data-testid='sectionInput_test_id'
							errorMessage={errors.section?.message}
						/>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<SelectInput
							name={'contract_type'}
							register={register}
							label={
								<>
									Contrato <ObrigatoryIndicator />{' '}
								</>
							}
							data-testid='contractInput_test_id'
							errorMessage={errors.contract_type?.message}
							containerStyle={{ maxWidth: '49.5%' }}
						>
							<option value='CLT'>CLT</option>
							<option value='TRAINEE'>Estágio</option>
							<option value='SHAREHOLDER'>Sócio</option>
							<option value='PJ'>PJ</option>
						</SelectInput>

						<div
							style={{ display: 'flex', width: '100%', marginLeft: '0.5rem' }}
						>
							<SelectInput
								name={'id_cost_center'}
								register={register}
								label={
									<>
										Centro de custo <ObrigatoryIndicator />
									</>
								}
								data-testid='costCenterInput_test_id'
								errorMessage={errors.id_cost_center?.message}
							>
								<option disabled value=''>
									Selecione uma opção
								</option>
								{fetchCostCentersQuery.data?.map((costCenter) => (
									<option key={costCenter.id} value={costCenter.id}>
										{costCenter.title}
									</option>
								))}
							</SelectInput>
							<CostCenterCreationModal smallButtonStyle />
						</div>
					</FormStyle.FieldGroup>

					<InputField
						name={'branch_office'}
						register={register}
						label={'Filial'}
						data-testid='branchOfficeInput_test_id'
						errorMessage={errors.branch_office?.message}
						containerStyle={{ maxWidth: '49%' }}
					/>
				</FormStyle.FieldSet>

				<FormStyle.Divider />

				{/* ADDRESS FIELDSET */}
				<FormStyle.FieldSet>
					<FormStyle.FieldGroup>
						<InputField
							name={'address'}
							register={register}
							label={
								<>
									Endereço <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</>
							}
							data-testid='addressInput_test_id'
							errorMessage={errors.address?.message}
						/>
						<InputField
							name={'number'}
							register={register}
							label={
								<>
									Número <ObrigatoryIndicator />
									<KYCFieldsIndicator />
									<S.CheckBoxContainer>
										<input
											type='checkbox'
											id='checkbox'
											checked={hasNoNumber}
											onChange={checkNoNumber}
										/>
										<S.CheckBoxLabel htmlFor='checkbox'>
											Não possui número
										</S.CheckBoxLabel>
									</S.CheckBoxContainer>
								</>
							}
							data-testid='numberInput_test_id'
							errorMessage={errors.number?.message}
							containerStyle={{ maxWidth: '25%' }}
							disabled={hasNoNumber}
						/>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<InputField
							name={'district'}
							register={register}
							label={
								<>
									Bairro <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</>
							}
							data-testid='districtInput_test_id'
							errorMessage={errors.district?.message}
							placeholder='Ex: Boa Vista'
							maxLength={30}
						/>

						<InputField
							name={'cep'}
							register={register}
							label={
								<>
									CEP <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</>
							}
							data-testid='cepInput_test_id'
							placeholder='Ex: 99999-999'
							onChange={(event) => {
								const { value } = event.target;
								setValue('cep', cepMask(value), { shouldValidate: true });
							}}
							containerStyle={{ maxWidth: '25%' }}
							errorMessage={errors.cep?.message}
						/>
						<SearchCEPButton
							isLoading={isSearchingCEP}
							onClick={() => searchAddressByCEP(getValues().cep)}
						/>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<InputField
							name={'complement'}
							register={register}
							label={
								<div
									style={{
										gap: '0.5rem',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									Complemento
									<IconHover
										Icon={AiOutlineQuestionCircle}
										textHover={warningOnlyNumbersComplementMessage}
										sizeIcon='1.4rem'
									/>
								</div>
							}
							data-testid='complementInput_test_id'
							placeholder='Ex: Bloco D, Apartamento 02'
							maxLength={30}
							errorMessage={errors.complement?.message}
						/>

						<InputField
							name={'reference'}
							register={register}
							label={'Referência'}
							data-testid='referenceInput_test_id'
							maxLength={30}
							errorMessage={errors.reference?.message}
						/>
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<InputField
							name={'city'}
							register={register}
							label={
								<>
									Cidade <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</>
							}
							data-testid='cityInput_test_id'
							maxLength={30}
							containerStyle={{ maxWidth: '41%' }}
							errorMessage={errors.city?.message}
						/>
						<SelectInput
							name={'uf'}
							register={register}
							label={
								<>
									UF <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</>
							}
							data-testid='ufInput_test_id'
							containerStyle={{ maxWidth: '7%' }}
							errorMessage={errors.uf?.message}
						>
							<option disabled value=''>
								Selecione uma opção
							</option>
							{UF_List.map((uf) => (
								<option key={uf} value={uf}>
									{uf}
								</option>
							))}
						</SelectInput>
					</FormStyle.FieldGroup>
				</FormStyle.FieldSet>

				<FormStyle.Divider />

				{/* CONTACT FIELDSET */}
				<FormStyle.FieldSet>
					<FormStyle.FieldGroup style={{ alignItems: 'center', gap: '1rem' }}>
						<InputField
							type={'email'}
							name={'email'}
							register={register}
							label={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: '0.5rem',
									}}
								>
									Email
									<ObrigatoryIndicator />
									<AlertValidEmail marginTop={'0'} />
								</div>
							}
							data-testid='emailInput_test_id'
							placeholder='Ex: exemplo@email.com'
							errorMessage={errors.email?.message}
							onChange={(e) => {
								checkEmailDomain(e.target.value);
								setValue('email', e.target.value, { shouldValidate: true });
							}}
							containerStyle={{ maxWidth: '49%' }}
						/>
						{hasDifferentEmailDomain && (
							<IconHover
								Icon={FaExclamationCircle}
								textHover='Verificamos que o domínio de email do colaborador não corresponde ao domínio da empresa. Verifique se o endereço de email é o corporativo e não o pessoal. Caso esteja ciente que é o endereço correto você pode ignorar essa mensagem.'
								colorIcon='#FF5900'
							/>
						)}
					</FormStyle.FieldGroup>

					<FormStyle.FieldGroup>
						<InputField
							type={'tel'}
							name={'first_phone'}
							register={register}
							label={
								<>
									Telefone 1 <ObrigatoryIndicator />
									<KYCFieldsIndicator />
								</>
							}
							data-testid='telInput_test_id'
							placeholder='Ex: +55 81 99999-9999'
							errorMessage={errors.first_phone?.message}
							onChange={(event) => {
								const { value } = event.target;
								setValue('first_phone', phoneMask(value), {
									shouldValidate: true,
								});
							}}
						/>
						<InputField
							type={'tel'}
							name={'second_phone'}
							register={register}
							label={'Telefone 2'}
							data-testid='tel2Input_test_id'
							placeholder='Ex: +55 81 99999-9999'
							errorMessage={errors.second_phone?.message}
							onChange={(event) => {
								const { value } = event.target;
								setValue('second_phone', phoneMask(value), {
									shouldValidate: true,
								});
							}}
						/>
					</FormStyle.FieldGroup>
				</FormStyle.FieldSet>
			</FormStyle.FormContainer>

			<ObrigatoryFieldsIndication />
			<KYCFieldsIndication />
			<AlertValidEmailText />
			<FormStyle.ButtonsContainer>
				<FormStyle.LeftButtonsContainer>
					<FormStyle.FormButton type='submit'>Salvar</FormStyle.FormButton>
					{changeStatus &&
						addCollabToTheGroups &&
						addedGroups &&
						onDelete &&
						removeCollabFromGroups && (
							<div
								style={{ display: 'flex', width: '100%', columnGap: '1rem' }}
							>
								{collaborator?.status && (
									<GroupSelector
										alreadyAddedGroups={
											addedGroups.map((g) => g.id) as string[]
										}
										onAddNewGroups={addCollabToTheGroups}
										onRemoveGroups={removeCollabFromGroups}
										groupSelectorToCollaborator={true}
									/>
								)}
								<ManageBenefitsModal collaboratorId={collaborator!.id!} />
								<FormStyle.Option
									onClick={(e) => {
										e.preventDefault();
										changeStatus();
									}}
									data-testid='changeStatus_button'
								>
									{collaborator!.status ? 'Desativar ' : 'Ativar '}colaborador
								</FormStyle.Option>
								<S.Option
									cancel={true}
									onClick={(e) => {
										e.preventDefault();
										onDelete();
									}}
									style={{ marginLeft: 'auto' }}
									data-testid='deleteCollab_button'
								>
									Remover
								</S.Option>
							</div>
						)}
				</FormStyle.LeftButtonsContainer>
			</FormStyle.ButtonsContainer>
		</FormStyle.Form>
	);
};

export default CollaboratorForm;
