import { useEffect } from 'react';
import { UseFormRegister, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
	NotificationPreferenceType,
	NotificationsPreferences,
} from '../../../../@types/CorporateExpenses/Preferences';
import { AsynchronousContent } from '../../../../componentsV2/AsynchronousContent';
import { useAuth } from '../../../../hooks/useAuth';
import {
	CORPWAY_PREFERENCES_KEY,
	getPreferences,
	updatePreferences,
} from '../../../../services/queries/Corpway/Preferences';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { Financial } from './Financial';
import { Transactions } from './Transactions';
import { User } from './User';

export interface NotificationComponentProps {
	register: UseFormRegister<FormDataType>;
	onChangePref: (p: Partial<NotificationsPreferences>) => void;
	isLoading: boolean;
}

export type FormDataType = {
	[key in keyof NotificationsPreferences]?: NotificationPreferenceType;
};

export function Notifications({
	activeTab,
}: {
	activeTab: 'user' | 'finances' | 'transactions';
}) {
	const { user } = useAuth();
	const queryClient = useQueryClient();
	const { register, reset } = useForm<FormDataType>();

	const getPreferencesQuery = useQuery(
		[CORPWAY_PREFERENCES_KEY, user.id],
		getPreferences,
		{
			onSuccess({ operatorPreferences: prefs }) {
				const updatedData: FormDataType = {
					user_created: prefs.user_created,
					user_deleted: prefs.user_deleted,
					funds_in_solicitation: prefs.funds_in_solicitation, // you can change between 'email' or 'both' (meaning the email is obrigatory and the notification is not)
					funds_in: prefs.funds_in,
					card_limit_reached: prefs.card_limit_reached,
					users_without_card: prefs.users_without_card,
					payment_requested: prefs.payment_requested,
					payment_realized: prefs.payment_realized,
					payment_declined: prefs.payment_declined,
					ted_successful: prefs.ted_successful,
					ted_failed: prefs.ted_failed,
					ted_scheduled: prefs.ted_scheduled,
					ted_balance: prefs.ted_balance,
					ted_canceled: prefs.ted_canceled,
					ted_limit: prefs.ted_limit,
				};

				reset(updatedData);
			},
			onError(err) {
				showErrorMessage(
					err as Error,
					'Não foi possível buscar as preferências. '
				);
			},
		}
	);

	useEffect(() => {
		const prefs = getPreferencesQuery.data?.operatorPreferences;

		if (prefs !== undefined) {
			const updatedData: FormDataType = {
				user_created: prefs.user_created,
				user_deleted: prefs.user_deleted,
				funds_in_solicitation: prefs.funds_in_solicitation, // you can change between 'email' or 'both' (meaning the email is obrigatory and the notification is not)
				funds_in: prefs.funds_in,
				card_limit_reached: prefs.card_limit_reached,
				users_without_card: prefs.users_without_card,
				payment_requested: prefs.payment_requested,
				payment_realized: prefs.payment_realized,
				payment_declined: prefs.payment_declined,
				ted_successful: prefs.ted_successful,
				ted_failed: prefs.ted_failed,
				ted_scheduled: prefs.ted_scheduled,
				ted_balance: prefs.ted_balance,
				ted_canceled: prefs.ted_canceled,
				ted_limit: prefs.ted_limit,
			};

			reset(updatedData);
		}
	}, [activeTab]); // eslint-disable-line

	const updatePreferencesMutation = useMutation(
		(data: NotificationsPreferences) => updatePreferences(data),
		{
			onSuccess() {
				queryClient.invalidateQueries([CORPWAY_PREFERENCES_KEY, user.id]);
			},
			onError(err) {
				showErrorMessage(
					err as Error,
					'Ocorreu algum problema ao tentar atualizar as preferências. '
				);
			},
		}
	);

	function handleChangePreference(preference: NotificationsPreferences) {
		updatePreferencesMutation.mutate(preference as NotificationsPreferences);
	}

	return (
		<AsynchronousContent status={getPreferencesQuery.status}>
			{activeTab === 'user' && (
				<User
					register={register}
					onChangePref={handleChangePreference}
					isLoading={updatePreferencesMutation.isLoading}
				/>
			)}
			{activeTab === 'finances' && (
				<Financial
					register={register}
					onChangePref={handleChangePreference}
					isLoading={updatePreferencesMutation.isLoading}
				/>
			)}
			{activeTab === 'transactions' && (
				<Transactions
					register={register}
					onChangePref={handleChangePreference}
					isLoading={updatePreferencesMutation.isLoading}
				/>
			)}
		</AsynchronousContent>
	);
}
