import { parseMovementType } from "../../Management/utils/parseBalanceMovements";

type TransactionFilter = {
    label: string;
    isActive: boolean;
};

export type TransactionFilters = Record<string, TransactionFilter>;

export function getInitialTransactionFilters(shouldShowFundsOut: boolean): TransactionFilters {
    return {
        chargeback: {
            label: 'Estorno para carteira',
            isActive: false,
        },
        fundsIn: {
            label: 'Recarga de carteira',
            isActive: false,
        },
        external_chargeback: {
            label: 'Receb. via chargeback',
            isActive: false,
        },
        cardRecharge: {
            label: 'Repasse para cartão',
            isActive: false,
        },
        ...(shouldShowFundsOut && {
            externalTransaction: {
                label: 'Transferência externa',
                isActive: false,
            },
        }),
    };
}

export function isFilterActive(transactionFilters: TransactionFilters, movementType: string): boolean {
    let filterActive = false;
    let filterTransactionOut = false;

    Object.entries(transactionFilters).forEach(([_, { isActive, label }]) => {
        if (isActive) filterActive = true;
        if (label === movementType && !isActive) filterTransactionOut = true;
    });

    return filterActive && filterTransactionOut;
}

export const getTransactionFilters = (
    movements: any[],
    shouldShowFundsOut: boolean
): TransactionFilters => {
    const showCreditiInitialFilter = movements.some(
        (movement) =>
            movement && parseMovementType(movement.type, movement.action) === "Crédito inicial"
    );

    const showBalanceRechargeFilter = movements.some(
        (movement) =>
            movement && parseMovementType(movement.type, movement.action) === "Adição de saldo"
    );

    const showRemovalBalanceFilter = movements.some(
        (movement) =>
            movement && parseMovementType(movement.type, movement.action) === "Remoção de saldo"
    );

    const dynamicFilters: TransactionFilters = {
        ...(showCreditiInitialFilter && {
            first_credit_receipt: {
                label: 'Crédito inicial',
                isActive: false,
            },
        }),
        ...(showBalanceRechargeFilter && {
            balance_recharge_receipt: {
                label: 'Adição de saldo',
                isActive: false,
            },
        }),
        ...(showRemovalBalanceFilter && {
            company_chargeback: {
                label: 'Remoção de saldo',
                isActive: false,
            },
        }),
    };

    const baseFilters: TransactionFilters = {
        chargeback: {
            label: 'Estorno para carteira',
            isActive: false,
        },
        fundsIn: {
            label: 'Recarga de carteira',
            isActive: false,
        },
        external_chargeback: {
            label: 'Receb. via chargeback',
            isActive: false,
        },
        cardRecharge: {
            label: 'Repasse para cartão',
            isActive: false,
        },
        ...(shouldShowFundsOut && {
            externalTransaction: {
                label: 'Transferência externa',
                isActive: false,
            },
        }),
    };

    return {
        ...baseFilters,
        ...dynamicFilters,
    };
};
