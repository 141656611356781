export function cpfMask(value: string): string {
	return value
		.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
		.replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function cepMask(value: string) {
	var re = /^([\d]{5})-?([\d]{3})/;

	return value.replace(/\D/g, '').replace(re, '$1-$2').substring(0, 9);
}

export function crnmMask(value: string) {
	return value
		.replace(/[\W_]+/g, '')
		.toUpperCase()
		.substring(0, 8);
}

export function phoneMask(value: string) {
	// regex for testing the pattern
	// const re = /\+([0-9]+( [0-9]+)+)-([0-9])/i;
	value = value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '+$1 $2')
		.replace(/(\d{2})(\d)/, '$1 $2');

	if (value.length < 16) {
		return value.replace(/(\d{4})(\d)/, '$1-$2');
	}

	return value.replace(/(\d{5})(\d)/, '$1-$2').substring(0, 17);
}

export function cnpjMask(value: string) {
	if (value.length <= 18) {
		value = value
			.replace(/\D/g, '')
			.replace(/(\d{2})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1/$2')
			.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
	}
	return value.substring(0, 18);
}

export function parseDateToInput(date?: string) {
	if (!date) return '';

	const length = date.length;
	try {
		return `${date.substring(length, length - 4)}-${date.substring(
			length - 5,
			length - 7
		)}-${date.substring(0, 2)}`;
	} catch (err) {
		return date;
	}
}
export function parseInputToDate(date: string) {
	const length = date.length;
	try {
		const dateStr = date.substring(length - 2, length);
		const monthStr = date.substring(length - 5, length - 3);
		const yearStr = date.substring(0, 4);

		return `${dateStr}/${monthStr}/${yearStr}`;
	} catch (err) {
		return date;
	}
}

// PARSE TO RAW
export function parseMaskedCPFToRaw(maskedCPF: string) {
	return maskedCPF.replaceAll('.', '').replaceAll('-', '');
}

export function parseMaskedCEPToRaw(maskedCPF: string) {
	return maskedCPF.replaceAll('-', '');
}

export function parseMaskedPhoneToRaw(maskedPhone: string) {
	return maskedPhone
		.replaceAll(' ', '')
		.replaceAll('+', '')
		.replaceAll('-', '');
}

export function parseMaskedCnpjToRaw(maskedCnpj: string) {
	return maskedCnpj.replaceAll('-', '').replaceAll('.', '').replaceAll('/', '');
}

export const moneyMask = (value: string) => {
	value = value.replaceAll('.', '').replace(',', '').replace(/\D/g, '');

	const options = { minimumFractionDigits: 2 };
	const result = new Intl.NumberFormat('pt-BR', options).format(
		isNaN(parseFloat(value)) ? 0 : parseFloat(value) / 100
	);

	return result;
};

export const parseMoneyMaskToRaw = (value: string) => {
	return value.replaceAll('.', '').replace(',', '');
};

export function capitalizeFirstLetter(val: string) {
	if (val.length > 1)
		return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
	else return val.toUpperCase();
}

export function onlyPositiveNumbersMask(str: string, maxLength: number) {
	const onlyNumbers = str.replace(/[^0-9]/g, '');

	if (String(onlyNumbers).length > maxLength) {
		return onlyNumbers.slice(0, maxLength);
	}

	return onlyNumbers;
}

export function onlyNumbersWithSingleHyphenMask(
	str: string,
	maxLength: number
) {
	let filteredStr = str.replace(/[^0-9-]/g, '');

	const hyphenIndex = filteredStr.indexOf('-');
	if (hyphenIndex !== -1) {
		filteredStr =
			filteredStr.slice(0, hyphenIndex + 1) +
			filteredStr.slice(hyphenIndex + 1).replace(/-/g, '');
	}

	if (filteredStr.length > maxLength) {
		return filteredStr.slice(0, maxLength);
	}

	return filteredStr;
}

export function formatVerifierDigit(value: string) {
	if (value.length > 1) {
		return value.slice(0, -1) + '-' + value.slice(-1);
	}
	return value;
}
