import { Company } from '../../../@types';
import {
	SortColumn,
	parseSortColumnToParams,
} from '../../../hooks/useSortColumnHook';
import api from '../../api';

export interface fundsHistory {
	amount: string;
	category: string;
	company: Company;
	company_id: string;
	created_at: string;
	id: string;
	origin: string;
	resource_id: string | null;
	type: string;
	updated_at: string;
}

export interface BalanceMovement {
	type: 'billing' | 'pix' | 'card_fund' | 'funds_out' | 'external_chargeback' | 'first_credit_receipt' | 'balance_addition_from_master';
	id: string;
	amount: number;
	amount_paid?: number;
	payment_date?: string;
	status?: string;
	company_id: string;
	recipient_email?: string;
	operator_id?: string;
	company?: {
		id: string;
		name: string;
		cnpj: string;
	};
	operator?: {
		id: string;
		name: string;
	};
	action?: string;
	card_id?: string;
	card?: {
		id: string;
		alias: string;
		pan: string;
		status: string;
		cost_center: string;
		responsible: string;
	};
	beneficiary?: {
		name: string;
		type: "pf" | "pj";
		document: string;
		bank_code: string;
		bank_name: string;
		branch: string;
		account_number: string;
		account_type: "checking" | "saving";
	}
	source?: {
		account_number: string
		account_type: string
		bank_code: string
		bank_name: string
		branch: string
		document: string
		name: string
	}
	external_id?: string
	transfer_type?: string;
	error_msg?: string;
	sender_name?: string;
	scheduled_date?: string;
	description: string;
	created_at: Date;
	updated_at: Date;
}

export interface FundsReturn {
	fundsHistory: fundsHistory[];
	totalHistory: number;
}

export interface MovementsReturn {
	balanceMovements: BalanceMovement[];
	totalMovements: number;
	totalReports?: number;
}

export async function getCorpwayFunds(
	companyId: string,
	page?: number,
	sortColumn: SortColumn | null = null
) {
	const { data } = await api.get<FundsReturn>(
		`api/v1/rh/corpway/companies/${companyId}/funds`,
		{
			params: {
				page,
				...parseSortColumnToParams(sortColumn),
			},
		}
	);
	return data;
}

export async function getBalanceMovements(
	companyId: string,
	start_date: number,
	end_date: number,
	sortColumn: SortColumn | null = null
) {
	const { data } = await api.get<MovementsReturn>(
		`api/v1/rh/corpway/companies/${companyId}/movements`,
		{
			params: {
				start_date,
				end_date,
				...parseSortColumnToParams(sortColumn),
			},
		}
	);
	return data;
}

export interface MovementsReportProps {
	start_date: number;
	end_date: number;
	operationTypes: {
		funds_in: boolean;
		wallet_refund: boolean;
		card_funds: boolean;
		funds_out: boolean;
		external_chargeback: boolean;
		first_credit_receipt: boolean;
		balance_addition_from_master: boolean;
	};
	operationStatuses: {
		all_statuses: boolean;
		created: boolean;
		overdue: boolean;
		paid: boolean;
		processing: boolean;
		scheduled: boolean;
	};
	fundsInOrigin?: 'all_origins' | 'pix' | 'billing';
}

export async function getBalanceMovementsToReport(
	companyId: string,
	start_date: number,
	end_date: number,
	operationTypes: MovementsReportProps['operationTypes'],
	operationStatuses: MovementsReportProps['operationStatuses'],
	fundsInOrigin?: 'all_origins' | 'pix' | 'billing'
) {
	const { data } = await api.post<MovementsReturn>(
		`api/v1/rh/corpway/companies/${companyId}/movements/report`,
		{
			operationTypes,
			operationStatuses,
		},
		{
			params: {
				start_date,
				end_date,
				fundsInOrigin,
			},
		}
	);
	return data;
}
