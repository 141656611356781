import React, { useState } from 'react';
import { Button } from '../../../../../componentsV2/ui/Button';
import Modal from '../../../../../componentsV2/ui/Modal';
import { CostCenterForm } from './Form';
import { Card } from '../../../../../componentsV2/Card';
import { useMutation, useQueryClient } from 'react-query';
import {
	CORPWAY_COST_CENTER_KEY,
	createCostCenter,
} from '../../../../../services/queries/Corpway/CostCenters';
import { useAuth } from '../../../../../hooks/useAuth';
import { CORPWAY_MANAGEMENT_KEY } from '../../../../../services/queries/Corpway/Management';
import { showErrorMessage } from '../../../../../utils/ErrorHandler';
import { CreateUpdateCostCenterData } from '../../../../../@types/CorporateExpenses/CostCenter';
import styled from 'styled-components';
import costCenterIconModal from '../../../../../assets/cost-center.svg';
import costCenterConfirmation from '../../../../../assets/cost-center-confirmation.svg';
import { IoIosArrowForward } from 'react-icons/io';

interface Props {
	openBtn?: React.ReactNode;
}

export function CreateCostCenterModal({ openBtn }: Props) {
	const queryClient = useQueryClient();
	const { currentCompany } = useAuth();
	const [isOpen, setOpen] = useState(false);
	const [isConfirmationOpen, setConfirmationOpen] = useState(false);

	const createCostCenterMutation = useMutation(
		(d: CreateUpdateCostCenterData) => createCostCenter(d, currentCompany!.id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CORPWAY_COST_CENTER_KEY]);
				queryClient.invalidateQueries([CORPWAY_MANAGEMENT_KEY]);
				setOpen(false);
				setConfirmationOpen(true);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Não foi possível cadastrar o centro de custo. '
				);
			},
		}
	);

	const openModal = () => setOpen(true);

	function onSubmit(data: CreateUpdateCostCenterData) {
		data.title =
			data.title.charAt(0).toUpperCase() + data.title.slice(1).toLowerCase();
		createCostCenterMutation.mutate(data);
	}

	const CustomCreateTitle = () => {
		const phrase = 'Criação de centro de custo';
		const words = phrase.split(' ');
		const firstWord = words.shift(); // Remove a primeira palavra do array

		return (
			<TitleWrapper>
				<span>
					<BoldText>{firstWord}</BoldText> {words.join(' ')}
				</span>
			</TitleWrapper>
		);
	};

	const CustomConfirmationTitle = () => {
		return (
			<TitleWrapper>
				<span>Centro de custo</span>
				<BoldText>criado com sucesso</BoldText>
			</TitleWrapper>
		);
	};

	return (
		<div>
			{openBtn ? (
				React.cloneElement(openBtn as React.ReactElement, {
					onClick: openModal,
				})
			) : (
				<Button
					intent='primary'
					roundness='lg'
					type='button'
					onClick={openModal}
					style={{
						columnGap: '0.6rem',
						fontWeight: 550,
						fontFamily: 'poppins',
						fontSize: '1.5rem',
						padding: '1rem 3.5rem',
						paddingRight: '2.4rem',
					}}
				>
					Novo centro de custo <IoIosArrowForward />
				</Button>
			)}

			<Modal
				isOpen={isOpen}
				onRequestClose={() => setOpen(false)}
				showClose={false}
			>
				<ModalContainer>
					<CreateCardModal
						titleComponent={<CustomCreateTitle />}
						direction='vertical'
						iconSrc={costCenterIconModal}
					>
						<CostCenterForm
							onSubmit={onSubmit}
							onCancel={() => setOpen(false)}
							isLoading={createCostCenterMutation.isLoading}
						/>
					</CreateCardModal>
				</ModalContainer>
			</Modal>

			{/* new modal*/}
			<Modal
				isOpen={isConfirmationOpen}
				onRequestClose={() => setConfirmationOpen(false)}
				showClose={true}
			>
				<ConfirmationModal>
					<ConfirmationCard
						titleComponent={<CustomConfirmationTitle />}
						direction='vertical'
						iconSrc={costCenterConfirmation}
					>
						<Button
							onClick={() => setConfirmationOpen(false)}
							intent='terciary'
							$outline
							width='adjusted'
							type='button'
							style={{
								width: '11.3rem',
								height: '4.5rem',
								gap: '1.2rem',
								border: '1px solid #393939',
								borderRadius: '50rem',
								padding: '12px 32px 12px 32px',
							}}
						>
							Fechar
						</Button>
					</ConfirmationCard>
				</ConfirmationModal>
			</Modal>
		</div>
	);
}

const ModalContainer = styled.div`
	width: 100%;
	border-radius: 16px 0px 0px 0px;
`;

const CreateCardModal = styled(Card)`
	width: 100%;
	& > div {
		width: 44rem;
		height: 17rem;
		background-color: #f0f3f8;
		padding: 40px 0px 40px 0px;
	}
`;

const ConfirmationCard = styled(Card)`
	width: 100%;
	& > div {
		width: 44rem;
		height: 17rem;
		padding: 40px 0px 40px 0px;
		background-color: #f0f3f8;
	}
`;

const ConfirmationModal = styled.div`
	width: 44rem;
	height: 25.6rem;
	radius: 16px;
	padding: 0px 0px 32px 0px;
	border-radius: 16px 0px 0px 0px;
	overflow: hidden;
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	& > span:first-child {
		font-family: Poppins;
		font-size: 2rem;
		font-weight: 400;
		line-height: 3rem;
		text-align: center;
	}
`;

const BoldText = styled.span`
	font-family: Poppins;
	font-size: 2rem;
	font-weight: 600;
	line-height: 3rem;
	text-align: center;
`;
