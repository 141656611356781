import styled from 'styled-components';

const Text = styled.span`
	color: var(--primary-blue);
	font-size: 1.2rem;
	font-weight: normal;
	margin-top: 1rem;
`;

export function AlertValidEmail({ marginTop }: { marginTop?: string }) {
	return <Text style={{ marginTop }}>*</Text>;
}

export function AlertValidEmailText() {
	return <Text>* Lembre-se sempre de inserir um e-mail existente.</Text>;
}
