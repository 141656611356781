import * as S from './styles';
import PageTitle from '../../../components/PageTitle';
import { useMutation } from 'react-query';
import {
	generateBilling,
	generateQRCode,
	sendQRCodeByEmail,
} from '../../../services/queries/Tranfers';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/useAuth';
import { convertReaisToCents } from '../../../utils/CurrencyConvert';
import * as FormStyles from '../../../components/Form/FormStyles';
import Loader from '../../../components/Loader';
import { parseCurrencyToBRLStandard } from '../../../utils/parseCurrency';
import { showErrorMessage } from '../../../utils/ErrorHandler';
import { RiCloseLine } from 'react-icons/ri';
import { generateQRCodeImage } from '../../../utils/generateQRcodeImage';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '../../../componentsV2/flexiblebenefits/ui/Form/InputField';

const valueSchema = z
	.object({
		value: z.number().positive('O valor deve ser maior que zero'),
		customEmail: z.string().email('Insira um email válido').or(z.literal('')),
		qrCodeImg: z.string().optional(),
		billingPDFURL: z.string().optional(),
		recipientEmailOption: z.enum(['financial', 'operator', 'custom']),
	})
	.superRefine((data, ctx) => {
		if (data.recipientEmailOption === 'custom' && !data.customEmail) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Insira um email válido',
				path: ['customEmail'],
			});
		}
	});

type FormData = z.infer<typeof valueSchema>;

export default function Wallet() {
	const { currentCompany, user } = useAuth();
	const { formState, watch, setValue, reset, handleSubmit } = useForm<FormData>(
		{
			resolver: zodResolver(valueSchema),
			defaultValues: {
				value: 0,
				customEmail: '',
				recipientEmailOption: 'operator',
				qrCodeImg: '',
				billingPDFURL: '',
			},
		}
	);

	const value = watch('value');
	const customEmail = watch('customEmail');
	const EMAILS = {
		financial: undefined,
		operator: user.email,
		custom: customEmail,
	};
	const recipientEmailOption = watch('recipientEmailOption');
	const recipientEmail = EMAILS[recipientEmailOption as keyof typeof EMAILS];
	const qrCodeImg = watch('qrCodeImg');
	const billingPDFURL = watch('billingPDFURL');

	const generateQRCodeMutation = useMutation(
		async (amount: number) =>
			generateQRCode(amount, 'multiflex', currentCompany!.id),
		{
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar gerar o QRCode. Tente novamente.'
				);
			},
			onSuccess: async (data) => {
				setValue('qrCodeImg', await generateQRCodeImage(data.emv));
			},
		}
	);

	const generateBillingMutation = useMutation(
		async ({ amount, email }: { amount: number; email: string | undefined }) =>
			generateBilling(amount, 'multiflex', currentCompany!.id, email),
		{
			onSuccess: () => {
				toast.info(
					'O boleto ainda não está disponível. O destinatário será notificado quando ele estiver disponível e receberá via email o pdf.'
				);
			},
			onError: () => {
				toast.error(
					'Ocorreu um problema ao tentar gerar o boleto. Tente novamente.'
				);
			},
		}
	);

	const sendQRCodeByEmailMutation = useMutation(
		({ recipientEmail }: { recipientEmail?: string }) =>
			sendQRCodeByEmail(
				generateQRCodeMutation.data?.emv!,
				value,
				currentCompany!.id,
				recipientEmail
			),
		{
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar enviar o QRCode. '
				);
			},
			onSuccess: () => {
				toast.info('QR code enviado com sucesso via email.');
			},
		}
	);

	const isDisabled =
		generateQRCodeMutation.isLoading || generateBillingMutation.isLoading;

	function submit(type: 'billing' | 'pix') {
		const amountInCents = convertReaisToCents(value);
		if (type === 'pix') {
			generateQRCodeMutation.mutate(amountInCents);
		} else {
			generateBillingMutation.mutate({
				amount: amountInCents,
				email: recipientEmail,
			});
		}
	}

	function handleSubmitQRCodeToEmail() {
		sendQRCodeByEmailMutation.mutate({ recipientEmail });
	}

	function handleCopyQRCode() {
		navigator.clipboard.writeText(generateQRCodeMutation.data!.emv);

		toast.info('Código PIX copiado!', {
			autoClose: 3500,
		});
	}

	return (
		<S.Container>
			<PageTitle title='Solicitação de pagamento' />

			<S.Content>
				<S.FormContainer>
					<FormStyles.Field>
						<FormStyles.Label>Valor</FormStyles.Label>

						<FormStyles.FormCurrencyInput
							onValueChange={({ floatValue }) => setValue('value', floatValue)}
							value={value}
							data-testid='amount_input'
							disabled={isDisabled}
						/>
						{formState.errors.value && (
							<ErrorMessage>{formState.errors.value?.message}</ErrorMessage>
						)}
					</FormStyles.Field>

					<FormStyles.Field>
						<FormStyles.Label>Enviar email para</FormStyles.Label>

						<S.RecipientEmailContainer>
							<label>
								<input
									type='radio'
									checked={recipientEmailOption === 'financial'}
									onChange={() => {
										setValue('recipientEmailOption', 'financial');
										setValue('customEmail', '');
									}}
								/>
								Financeiro da empresa
							</label>
							<label>
								<input
									type='radio'
									checked={recipientEmailOption === 'operator'}
									onChange={() => {
										setValue('recipientEmailOption', 'operator');
										setValue('customEmail', '');
									}}
								/>
								Operador (você)
							</label>
							<label>
								<input
									type='radio'
									checked={recipientEmailOption === 'custom'}
									onChange={() => setValue('recipientEmailOption', 'custom')}
								/>
								Customizado
							</label>
							<FormStyles.Input
								placeholder='email_proprio@exemplo.com'
								disabled={recipientEmailOption !== 'custom'}
								value={customEmail}
								type='email'
								onChange={(e) => setValue('customEmail', e.target.value)}
							/>
							{formState.errors.customEmail?.message && (
								<ErrorMessage>
									{formState.errors.customEmail?.message}
								</ErrorMessage>
							)}
						</S.RecipientEmailContainer>
					</FormStyles.Field>

					<S.SubmitOptionsContainer>
						<S.SubmitButton
							disabled={isDisabled}
							onClick={handleSubmit(() => submit('pix'))}
						>
							{generateQRCodeMutation.isLoading ? (
								<Loader color='#fff' size={20} />
							) : (
								'GERAR PIX'
							)}
						</S.SubmitButton>

						<span> ou </span>

						<S.SubmitButton
							onClick={handleSubmit(() => submit('billing'))}
							disabled={isDisabled}
						>
							{generateBillingMutation.isLoading ? (
								<Loader color='#fff' size={20} />
							) : (
								'GERAR BOLETO'
							)}
						</S.SubmitButton>

						{(qrCodeImg || billingPDFURL) && (
							<S.ClearButton
								cancel
								data-rh='Limpar'
								type='button'
								onClick={() => reset()}
								data-testid='clear-page'
							>
								<RiCloseLine />
							</S.ClearButton>
						)}
					</S.SubmitOptionsContainer>
				</S.FormContainer>

				<S.GeneratedPaymentsContainer>
					{!!qrCodeImg && (
						<S.QRCodeContainer>
							<S.QRCodeFigure>
								<S.QRCodeImg src={qrCodeImg} alt='QRcode gerado' />
								<S.QRcodeCaption>
									Solicitação de pagamento* gerado por QR Code no valor de{' '}
									{parseCurrencyToBRLStandard(value)}
								</S.QRcodeCaption>
							</S.QRCodeFigure>

							<S.QRCodeButtonsContainer>
								<S.CopyQRCodeBtn onClick={handleCopyQRCode}>
									Copiar código
								</S.CopyQRCodeBtn>

								<S.CopyQRCodeBtn onClick={handleSubmitQRCodeToEmail}>
									{sendQRCodeByEmailMutation.isLoading ? (
										<Loader color='#fff' size={15} />
									) : (
										'Enviar p/ email'
									)}
								</S.CopyQRCodeBtn>
							</S.QRCodeButtonsContainer>
							<S.Caption>
								<span>*</span>Pagamento efetuado via Pix
							</S.Caption>
						</S.QRCodeContainer>
					)}
				</S.GeneratedPaymentsContainer>
			</S.Content>
		</S.Container>
	);
}
