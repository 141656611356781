import { useEffect, useState } from 'react';
import { Collaborator } from '../../../../@types';
import PageTitle from '../../../../components/PageTitle';
import { SingleCollaboratorSelector } from './SingleCollaboratorSelector';
import * as FormStyles from '../../../../components/Form/FormStyles';
import { useForm, useController } from 'react-hook-form';
import * as S from './styles';
import {
	BenefitWithValue,
	BenefitSelectorToChargeback,
} from './BenefitSelectorToChargeback';
import { toast } from 'react-toastify';
import Loader from '../../../../components/Loader';
import { useAuth } from '../../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { useDialogModal } from '../../../../hooks/useDialogModal';
import { useMutation } from 'react-query';
import {
	chargebackBodyQuery,
	createChargeback,
	createChargebackToAllbenefits,
} from '../../../../services/queries/Chargebacks';
import { getBenefitParsedTitle } from '../../../../utils/benefits/getBenefitParsedTitle';
import PreventTransitionPrompt from '../../../../components/PreventTransitionPrompt';
import { benefitRepresentingAllID } from './BenefitSelectorToChargeback';
import { convertReaisToCents } from '../../../../utils/CurrencyConvert';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '../../../../componentsV2/flexiblebenefits/ui/Form/InputField';
import TextAreaInput from '../../../../componentsV2/flexiblebenefits/ui/Form/TextAreaInput';

interface FormValues {
	selectedBenefits: any;
	value: number;
	justification: string;
}

const schema = z
	.object({
		justification: z
			.string()
			.min(1, { message: 'O campo justificativa deve ser preenchido' }),
		selectedBenefits: z
			.array(
				z.object({
					benefit_id: z.string(),
				})
			)
			.nonempty('Pelo menos um benefício deve ser selecionado'),
		value: z.number(),
	})
	.superRefine((values, ctx) => {
		const isAllBenefitsSelected = values.selectedBenefits.some(
			(benefit) => benefit.benefit_id === benefitRepresentingAllID
		);
		if (isAllBenefitsSelected && values.value !== 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "O valor deve ser 0 ao selecionar 'Todos os benefícios'.",
				path: ['value'],
			});
		}

		if (!isAllBenefitsSelected && values.value === 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'O valor não pode ser 0 para os benefícios selecionados.',
				path: ['value'],
			});
		}
	});

export function CreateChargeback() {
	const { user, currentCompany, updateCompanyBalance } = useAuth();
	const history = useHistory();
	const { openConfirmDialog, openOptionsDialog } = useDialogModal();
	const [selectedCollaborator, setSelectedCollaborator] =
		useState<Collaborator | null>(null);
	const [selectedBenefits, setSelectedBenefits] = useState<BenefitWithValue[]>(
		[]
	);
	const [allowNavigation, setAllowNavigation] = useState(true);

	useEffect(() => {
		setAllowNavigation(!(selectedBenefits.length || selectedCollaborator));
	}, [selectedBenefits, selectedCollaborator]);

	useEffect(() => {
		setSelectedBenefits([]);
	}, [selectedCollaborator]);

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		control,
		setValue,
	} = useForm<FormValues>({
		resolver: zodResolver(schema),
		defaultValues: {
			justification: '',
			value: 0,
			selectedBenefits: [],
		},
	});

	const { field } = useController({
		name: 'value',
		control,
	});

	const watchedValue = watch('value');

	const onSubmit = (data: { justification: string; value: number }) => {
		const { justification, value } = data;

		if (selectedBenefits[0].benefit_id === benefitRepresentingAllID) {
			openOptionsDialog(
				'Você tem certeza que deseja estornar todos os benefícios do colaborador?',
				'Sim',
				() => {
					createChargebackToAllBenefitsQuery.mutate(justification);
				},
				'Não',
				() => {
					return;
				}
			);
		} else {
			const benefit_ids = selectedBenefits.map((benefit) => benefit.benefit_id);

			const body = {
				value: convertReaisToCents(value),
				justification,
				benefit_ids,
				collaborator_id: selectedCollaborator!.id,
			};

			createChargebackQuery.mutate(body);
		}
	};

	function showSuccessMessage() {
		if (user.access_level === 'admin') {
			updateCompanyBalance.refetch();
		}
		const confirmationMessage =
			user.access_level === 'admin'
				? 'O estorno foi realizado com sucesso.'
				: 'A solicitação de estorno foi criada. Um usuário ADMINISTRADOR deverá aprová-la para que os pagamentos sejam efetivados.';

		openConfirmDialog(confirmationMessage, 'Entendi', () => {});
		history.push('/home/chargebacks');
	}

	function showReprovedChargebackBenefits(benefits: { title: string }[]) {
		const titles = benefits.map((benefit) => {
			return getBenefitParsedTitle(benefit.title);
		});
		let message =
			'Não foi possível realizar o estorno para tais benefícios: ' +
			titles.join(',');

		toast.warn(message);
	}

	const createChargebackQuery = useMutation(
		(body: chargebackBodyQuery) => {
			return createChargeback(body, currentCompany?.id);
		},
		{
			onSuccess: (data) => {
				setAllowNavigation(true);
				if (data.chargebackError.length) {
					showReprovedChargebackBenefits(data.chargebackError);
				}
				showSuccessMessage();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar criar o estorno.'
				);
			},
		}
	);

	const createChargebackToAllBenefitsQuery = useMutation(
		(justification: string) => {
			return createChargebackToAllbenefits(
				justification,
				currentCompany?.id,
				selectedCollaborator?.id
			);
		},
		{
			onSuccess: (data) => {
				setAllowNavigation(true);
				if (data.chargebackError.length) {
					showReprovedChargebackBenefits(data.chargebackError);
				}
				showSuccessMessage();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar criar o estorno.'
				);
			},
		}
	);

	return (
		<S.Container>
			{!allowNavigation && <PreventTransitionPrompt />}

			<PageTitle title='Solicitar estorno' />
			<S.Form onSubmit={handleSubmit(onSubmit)}>
				<S.FieldGroupTop>
					<S.Field>
						<S.Label>Colaborador</S.Label>
						<SingleCollaboratorSelector
							alreadyAddedCollaborator={selectedCollaborator}
							onSelectCollaborator={(c) => setSelectedCollaborator(c)}
						/>
					</S.Field>
					<S.Field>
						<S.Label>Benefícios</S.Label>
						<BenefitSelectorToChargeback
							alreadyAddedBenefits={selectedBenefits}
							onSelectBenefits={(benefits) => {
								setSelectedBenefits(benefits);
								setValue('selectedBenefits', benefits);
							}}
							selectedCollab={selectedCollaborator}
							{...register('selectedBenefits')}
						/>
						{errors.selectedBenefits && (
							<ErrorMessage>{errors.selectedBenefits.message}</ErrorMessage>
						)}
					</S.Field>
				</S.FieldGroupTop>

				<S.Field>
					<S.Label>Valor</S.Label>
					<S.CurrencyInput
						data-testid='valueInput_test_id'
						value={watchedValue}
						onValueChange={({ floatValue }) => {
							field.onChange(floatValue);
						}}
						disabled={
							selectedBenefits[0]?.benefit_id === benefitRepresentingAllID
						}
						{...register('value')}
					/>
					{errors.value && <ErrorMessage>{errors.value.message}</ErrorMessage>}
				</S.Field>
				<TextAreaInput
					label={
						<span style={{ fontSize: '1.6rem', fontWeight: 'normal' }}>
							Justificativa
						</span>
					}
					register={register}
					name={'justification'}
					data-testid={'justificationInput_test_id'}
					errorMessage={errors.justification?.message}
				/>
				<S.Submit
					type='submit'
					disabled={
						createChargebackQuery.isLoading ||
						createChargebackToAllBenefitsQuery.isLoading
					}
				>
					{!createChargebackQuery.isLoading &&
					!createChargebackToAllBenefitsQuery.isLoading ? (
						'Enviar'
					) : (
						<Loader size={20} color='var(--white)' />
					)}
				</S.Submit>
			</S.Form>
		</S.Container>
	);
}
