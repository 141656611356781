import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Modal from '../../../../components/Modal';
import { useAuth } from '../../../../hooks/useAuth';
import {
	createBenefitsGroup,
	createBenefitsGroupParams,
} from '../../../../services/queries/BenefitsGroups';
import { showErrorMessage } from '../../../../utils/ErrorHandler';
import { trimObjectData } from '../../../../utils/trimObjectData';
import * as S from './styles';
import * as FormStyle from '../../../../components/Form/FormStyles';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../../../../components/ObrigatoryFieldsIndicator';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler } from 'react-hook-form';
import InputField from '../../../../componentsV2/flexiblebenefits/ui/Form/InputField';
import TextAreaInput from '../../../../componentsV2/flexiblebenefits/ui/Form/TextAreaInput';

const formSchema = z.object({
	title: z
		.string()
		.min(1, { message: 'O campo nome do grupo deve ser preenchido' }),
	description: z
		.string()
		.min(1, { message: 'O campo de descrição deve ser preenchido' }),
});

type FormSchemaType = z.infer<typeof formSchema>;

export function BenefitGroupCreationModal() {
	const { currentCompany } = useAuth();
	const queryClient = useQueryClient();
	const [modalOpen, setModalOpen] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<FormSchemaType>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			title: '',
			description: '',
		},
	});

	const createBenefitGroupQuery = useMutation(
		({ title, description }: createBenefitsGroupParams) => {
			return createBenefitsGroup(title, description, currentCompany?.id);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('benefitsGroupsList');
				toast.info('Grupo de benefícios criado com sucesso');
				reset();
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao tentar criar o grupo de benefícios. '
				);
			},
		}
	);

	const handleSubmitBenefitGroupCreation: SubmitHandler<FormSchemaType> = (
		data
	) => {
		setModalOpen(false);
		createBenefitGroupQuery.mutate(trimObjectData(data));
	};

	return (
		<>
			<S.AddGroup
				onClick={() => {
					setModalOpen(true);
				}}
			>
				Adicionar grupo
			</S.AddGroup>

			<Modal
				isOpen={modalOpen}
				onRequestClose={() => {
					setModalOpen(false);
				}}
				enableClose
			>
				<form onSubmit={handleSubmit(handleSubmitBenefitGroupCreation)}>
					<FormStyle.FormContainer data-testid='benefitGroupCreation-modal-form'>
						<InputField
							name={'title'}
							label={
								<span style={{ fontSize: '1.4em' }}>
									Título do Grupo <ObrigatoryIndicator />
								</span>
							}
							register={register}
							errorMessage={errors.title?.message}
							data-testid='title_test_id'
						/>

						<TextAreaInput
							name={'description'}
							label={
								<span style={{ fontSize: '2em' }}>
									Descrição <ObrigatoryIndicator />
								</span>
							}
							register={register}
							errorMessage={errors.description?.message}
							data-testid='description_test_id'
						/>

						<ObrigatoryFieldsIndication />
						<FormStyle.ButtonsContainer>
							<FormStyle.FormButton type='submit'>Salvar</FormStyle.FormButton>
							<S.CancelOption
								onClick={() => {
									setModalOpen(false);
								}}
							>
								Cancelar
							</S.CancelOption>
						</FormStyle.ButtonsContainer>
					</FormStyle.FormContainer>
				</form>
			</Modal>
		</>
	);
}
