import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Modal from '../../Modal';
import * as S from './styles';
import * as FormStyles from '../../Form/FormStyles';
import {
	ObrigatoryFieldsIndication,
	ObrigatoryIndicator,
} from '../../ObrigatoryFieldsIndicator';
import PageTitle from '../../PageTitle';
import { cpfMask, phoneMask } from '../../../utils/masks';
import { UnderageResponsible } from '../../../@types';
import { RiParentLine } from 'react-icons/ri';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import InputField from '../../../componentsV2/flexiblebenefits/ui/Form/InputField';

const schema = z.object({
	full_name: z
		.string()
		.min(1, 'O campo nome deve ser preenchido')
		.regex(/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/, 'O campo nome não pode conter números'),
	cpf: z
		.string()
		.min(1, 'O CPF é obrigatório')
		.regex(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido'),
	phone: z
		.string()
		.min(1, 'O campo de telefone deve ser preenchido')
		.regex(
			/^\+55\s\d{2}\s\d{5}-\d{4}$/,
			'Insira o telefone no formato +55 XX XXXXX-XXXX'
		),
	relatedness_degree: z
		.string()
		.min(1, 'O grau de parentesco é obrigatório')
		.regex(
			/^[A-Za-zÀ-ÖØ-öø-ÿ\s'-]+$/,
			'O campo grau de parentesco não pode conter números'
		),
});

type FormData = z.infer<typeof schema>;

interface UnderageResponsibleModalProp {
	onSubmit(responsible: UnderageResponsible): void;
	responsible?: UnderageResponsible;
	smallButton: boolean;
	editable: boolean;
}

export function UnderageResponsibleModal({
	responsible,
	onSubmit,
	smallButton,
	editable,
}: UnderageResponsibleModalProp) {
	const [isOpen, setIsOpen] = useState(false);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<FormData>({
		resolver: zodResolver(schema),
		defaultValues: {
			...responsible,
			cpf: cpfMask(responsible?.cpf || ''),
			phone: phoneMask(responsible?.phone || ''),
			full_name: responsible?.full_name || '',
			relatedness_degree: responsible?.relatedness_degree ?? '',
		},
	});

	const handleSubmitForm: SubmitHandler<FormData> = (data) => {
		onSubmit(data);
		setIsOpen(false);
	};

	return (
		<>
			{smallButton ? (
				<S.OpenModalButtonSmall
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(true);
					}}
					data-rh={'Detalhar responsável'}
					data-testid='openButton_id'
				>
					<RiParentLine />
				</S.OpenModalButtonSmall>
			) : (
				<S.OpenModalButtonLarge
					onClick={(e) => {
						e.preventDefault();
						setIsOpen(true);
					}}
				>
					Detalhar responsável
				</S.OpenModalButtonLarge>
			)}

			<Modal
				isOpen={isOpen}
				enableClose={true}
				onRequestClose={() => setIsOpen(false)}
			>
				<S.ModalContainer>
					<PageTitle title='Responsável por colaborador menor de idade' />
					<FormStyles.Form
						id={'underage_responsible_form'}
						onSubmit={(e) => {
							e.preventDefault();
							handleSubmit(handleSubmitForm)();
							e.stopPropagation();
						}}
					>
						<FormStyles.FieldGroup>
							<InputField
								label={
									<>
										Nome completo <ObrigatoryIndicator />
									</>
								}
								register={register}
								name={'full_name'}
								data-testid='fullNameInput_test_id'
								disabled={!editable}
								errorMessage={errors.full_name?.message}
							/>

							<InputField
								label={
									<>
										CPF <ObrigatoryIndicator />
									</>
								}
								register={register}
								name={'cpf'}
								placeholder='Ex: 999.999.999-99'
								onChange={(event) => {
									const value = cpfMask(event.target.value);
									setValue('cpf', value, { shouldDirty: true });
								}}
								data-testid='cpfInput_test_id'
								disabled={!editable}
								errorMessage={errors.cpf?.message}
							/>
						</FormStyles.FieldGroup>

						<FormStyles.FieldGroup>
							<InputField
								name={'phone'}
								register={register}
								label={
									<>
										Telefone <ObrigatoryIndicator />
									</>
								}
								type={'tel'}
								placeholder='Ex: +55 99 99999-9999'
								onChange={(event) => {
									const value = phoneMask(event.target.value);
									setValue('phone', value, { shouldDirty: true });
								}}
								data-testid='phoneInput_test_id'
								disabled={!editable}
								errorMessage={errors.phone?.message}
							/>

							<InputField
								name={'relatedness_degree'}
								register={register}
								label={
									<>
										Grau de parentesco <ObrigatoryIndicator />
									</>
								}
								data-testid='relatednessInput_test_id'
								disabled={!editable}
								errorMessage={errors.relatedness_degree?.message}
							/>
						</FormStyles.FieldGroup>

						{editable && (
							<>
								<ObrigatoryFieldsIndication />
								<S.BottomOptionsContainer>
									<S.SaveButton
										type='submit'
										form={'underage_responsible_form'}
									>
										Salvar
									</S.SaveButton>
								</S.BottomOptionsContainer>
							</>
						)}
					</FormStyles.Form>
				</S.ModalContainer>
			</Modal>
		</>
	);
}
