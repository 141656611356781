import * as S from '../styles';
import Professional from '../../../../../../assets/professional.png';
import { Button } from '../../../../../../componentsV2/ui/Button';
import Suitcase from '../../../../../../assets/suitcase.png';
import { InputField } from '../../../../../../componentsV2/ui/Form/InputField';
import {
	cnpjMask,
	cpfMask,
	moneyMask,
	onlyPositiveNumbersMask,
	parseDateToInput,
	parseMaskedCnpjToRaw,
	parseMaskedCPFToRaw,
} from '../../../../../../utils/masks';
import { useMutation, useQuery } from 'react-query';
import {
	fetchBankList,
	getLimits,
	getNextWorkingDay,
	isBeneficiaryBankAccountValid,
	isBeneficiaryNameValid,
	isNationalHoliday,
	resentToken,
} from '../../../../../../services/queries/Corpway/Transfers';
import { SelectField } from '../../../../../../componentsV2/ui/Form/SelectField';
import {
	Beneficiary,
	Transfer,
} from '../../../../../../@types/CorporateExpenses/Transfer';
import { useAuth } from '../../../../../../hooks/useAuth';
import {
	parseCurrencyStrToNumber,
	parseCurrencyToBRLStandard,
} from '../../../../../../utils/parseCurrency';
import { convertReaisToCents } from '../../../../../../utils/CurrencyConvert';
import { IoMdArrowRoundDown, IoMdCalendar } from 'react-icons/io';
import { InputComp } from '../../../../../../componentsV2/ui/Form/InputStyle';
import { Typography } from '../../../../../../componentsV2/ui/Typography';
import React, { useEffect, useState } from 'react';
import { useValidateCorpwayUserPassword } from '../../../../../../hooks/useValidateCorpwayUserPassword';
import { PasswordInput } from '../../../../../../componentsV2/ui/Form/PasswordInput';
import { showErrorMessage } from '../../../../../../utils/ErrorHandler';
import { toast } from 'react-toastify';
import { FaCheck } from 'react-icons/fa';
import { defaultTransferValues, StepProps } from './index';
import { MobileContainer } from '../../../../../../componentsV2/ui/Utils';
import { SearchableSelectField } from '../../../../../../componentsV2/ui/Form/SearchableSelectField';
import { FieldError, UseFormReturn } from 'react-hook-form';
import { MdErrorOutline } from 'react-icons/md';

const InitialStep = ({
	onPf,
	onPj,
}: {
	onPf: () => void;
	onPj: () => void;
}) => {
	return (
		<>
			<S.Step1Container>
				<S.TypePartner>
					<S.IconContainer>
						<img src={Professional} alt='Ícone pessoas físicas' />
					</S.IconContainer>
					<S.PartnerInformation>
						<div style={{ border: 0 }}>
							<span>Transferência para:</span>
							<h1>Pessoa física</h1>
						</div>

						<p>
							Transfira valores para contas que pertencem a colaboradores,
							sócios ou outras pessoas físicas.
						</p>
						<div />
						<Button
							intent='primary'
							roundness='lg'
							width={'full'}
							onClick={onPf}
						>
							Transf. pessoa física
						</Button>
					</S.PartnerInformation>
				</S.TypePartner>

				<S.TypePartner>
					<S.IconContainer>
						<img
							style={{ height: '55%' }}
							src={Suitcase}
							alt='Ícone pessoas Jurídicas'
						/>
					</S.IconContainer>
					<S.PartnerInformation>
						<div style={{ border: 0 }}>
							<span>Transferência para:</span>
							<h1>Pessoa jurídica</h1>
						</div>
						<p>
							Transfira valores para contas que pertencem a sua empresa, a
							outras empresas e/ou instituições.
						</p>
						<div></div>
						<Button
							intent='secondary'
							roundness='lg'
							width={'full'}
							onClick={onPj}
						>
							Transf. pessoa jurídica
						</Button>
					</S.PartnerInformation>
				</S.TypePartner>
			</S.Step1Container>
			<MobileContainer style={{ textAlign: 'center', marginBottom: '1rem' }}>
				<S.DescriptionLabel>
					Arraste para o lado e veja as opções
				</S.DescriptionLabel>
			</MobileContainer>
		</>
	);
};

const MainDataForm = ({ form, setStep }: StepProps) => {
	const { register, trigger, formState, watch, reset } = form;
	const { currentCompany } = useAuth();

	const type = watch('beneficiary.type');

	const isBeneficiaryValidMutation = useMutation(
		['validateBeneficiaryName'],
		({ name, document }: { name: string; document: string }) =>
			isBeneficiaryNameValid(currentCompany!.id, {
				name,
				document,
			}),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Beneficiário inválido.');
			},
			onSuccess: () => setStep(2),
		}
	);

	function validateBeneficiary({
		name,
		document,
		type,
	}: {
		name: string;
		document: string;
		type: 'pf' | 'pj';
	}) {
		const documentRaw =
			type === 'pf'
				? parseMaskedCPFToRaw(document)
				: parseMaskedCnpjToRaw(document);

		isBeneficiaryValidMutation.mutate({
			name,
			document: documentRaw,
		});
	}

	return (
		<S.GeneralContainer>
			<S.FormContainer>
				<S.Title>Dados do favorecido:</S.Title>
				<S.StyledInput
					name={'beneficiary.name'}
					register={register}
					autoComplete='off'
					label={type === 'pf' ? 'Nome completo:' : 'Razão social:'}
					placeholder={
						type === 'pf'
							? 'Nome completo do beneficiário'
							: 'Razão social da empresa'
					}
					errorMessage={formState.errors.beneficiary?.name?.message}
				/>
				<S.StyledInput
					name={'beneficiary.document'}
					register={register}
					autoComplete='off'
					label={type === 'pf' ? 'CPF:' : 'CNPJ:'}
					placeholder={type === 'pf' ? '000.000.000-00' : '00.000.000/0000-00'}
					onChange={(e) => {
						const value = e.target.value;
						e.target.value = type === 'pf' ? cpfMask(value) : cnpjMask(value);
					}}
					errorMessage={formState.errors.beneficiary?.document?.message}
				/>
			</S.FormContainer>
			<S.ButtonsContainer>
				<Button
					type={'button'}
					roundness={'lg'}
					intent={'terciary'}
					$outline
					onClick={() => {
						setStep(0);
						reset(defaultTransferValues);
					}}
				>
					Voltar
				</Button>
				<Button
					type={'submit'}
					roundness={'lg'}
					intent={'primary'}
					loading={isBeneficiaryValidMutation.isLoading}
					shrinkOnLoading={false}
					onClick={() => {
						trigger(['beneficiary.name', 'beneficiary.document']).then(
							(valid) => {
								if (valid)
									validateBeneficiary({
										name: watch('beneficiary.name')!,
										document: watch('beneficiary.document')!,
										type: watch('beneficiary.type')!,
									});
							}
						);
					}}
				>
					Avançar
				</Button>
			</S.ButtonsContainer>
		</S.GeneralContainer>
	);
};

const BankDataForm = ({ form, setStep }: StepProps) => {
	const { register, trigger, formState, setValue, watch, getValues } = form;
	const { currentCompany } = useAuth();

	const getBanksQuery = useQuery(['banks'], fetchBankList, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	const options =
		getBanksQuery.data?.map((bank) => {
			return {
				value: bank.code,
				label: bank.code + ' - ' + bank.name,
				filterValue: bank.name,
			};
		}) ?? [];

	const bank_code = watch('beneficiary.bank_code');

	const isBeneficiaryBankAccountValidMutation = useMutation(
		['validateBeneficiaryBankAccount'],
		(beneficiary: Beneficiary) =>
			isBeneficiaryBankAccountValid(currentCompany!.id, beneficiary),
		{
			onError: (err) => {
				showErrorMessage(err as Error, 'Beneficiário inválido.');
			},
			onSuccess: () => setStep(3),
		}
	);

	function validateBankAccount(beneficiary: Beneficiary) {
		const updatedBeneficiary = { ...beneficiary };

		updatedBeneficiary.account_number =
			updatedBeneficiary.account_number + '-' + updatedBeneficiary.check_digit;
		updatedBeneficiary.document =
			updatedBeneficiary.type === 'pf'
				? parseMaskedCPFToRaw(updatedBeneficiary.document!)
				: parseMaskedCnpjToRaw(updatedBeneficiary.document!);

		delete updatedBeneficiary.check_digit; //unset unnecessary check_digit at this point

		isBeneficiaryBankAccountValidMutation.mutate(updatedBeneficiary);
	}

	function parseErrorMessage(hasBranchError?: FieldError, hasNumberError?: FieldError, hasDigitError?: FieldError): string {
		const errorMessages = [];

		if (hasBranchError) {
			errorMessages.push('agência');
		}
		if (hasNumberError) {
			errorMessages.push('conta');
		}
		if (hasDigitError) {
			errorMessages.push('dígito');
		}

		if (errorMessages.length === 0) {
			return ''; 
		}

		return `É obrigatório o preenchimento da ${errorMessages.join(', ').replace(/, ([^,]*)$/, ' e $1')}.`;
	}


	return (
		<S.GeneralContainer>
			<S.FormContainer>
				<S.Title>Dados bancários:</S.Title>
				<SearchableSelectField
					placeholder={'Selecione o banco:'}
					name={'beneficiary.bank_code'}
					label={'Banco:'}
					autoComplete='off'
					value={bank_code}
					onItemSelect={(e) => setValue('beneficiary.bank_code', e)}
					options={options}
					errorMessage={formState.errors.beneficiary?.bank_code?.message}
				/>

				<div
					style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
				>
					<S.InputContainerRow>
						<S.StyledInput
							type={'tel'}
							autoComplete='off'
							name={'beneficiary.branch'}
							label={'Agência:'}
							onChange={(e) => {
								e.target.value = onlyPositiveNumbersMask(e.target.value, 4);
							}}
							register={register}
							placeholder={'0000'}
						/>

						<S.StyledInput
							name={'beneficiary.account_number'}
							type={'tel'}
							autoComplete='off'
							label={'Conta:'}
							onChange={(e) => {
								e.target.value = onlyPositiveNumbersMask(e.target.value, 15);
							}}
							register={register}
							placeholder={'00000'}
						/>
						<S.InputDigitContainer>
							<S.StyledInput
								name={'beneficiary.check_digit'}
								type={'tel'}
								autoComplete='off'
								label={'Dígito:'}
								onChange={(e) => {
									e.target.value = onlyPositiveNumbersMask(e.target.value, 3);
								}}
								register={register}
								placeholder={'0'}
							/>
						</S.InputDigitContainer>
					</S.InputContainerRow>
					{(formState.errors.beneficiary?.check_digit ||
						formState.errors.beneficiary?.account_number ||
						formState.errors.beneficiary?.branch) && (
						<S.ErrorMessage>
							<MdErrorOutline />
							{parseErrorMessage(
								formState.errors.beneficiary?.branch,
								formState.errors.beneficiary?.account_number,
								formState.errors.beneficiary?.check_digit
							)}
						</S.ErrorMessage>
					)}
				</div>

				<SelectField
					name={'beneficiary.account_type'}
					label={'Tipo de conta:'}
					register={register}
					autoComplete='off'
					placeholder={'Selecione o tipo de conta:'}
					errorMessage={formState.errors.beneficiary?.account_type?.message}
				>
					<option value={'checking'}>Conta Corrente</option>
					<option value={'savings'}>Conta Poupança</option>
				</SelectField>
			</S.FormContainer>
			<S.ButtonsContainer>
				<Button
					type={'button'}
					roundness={'lg'}
					intent={'terciary'}
					$outline
					onClick={() => setStep(1)}
				>
					Voltar
				</Button>
				<Button
					type={'submit'}
					roundness={'lg'}
					intent={'primary'}
					loading={isBeneficiaryBankAccountValidMutation.isLoading}
					shrinkOnLoading={false}
					onClick={() => {
						trigger([
							'beneficiary.bank_code',
							'beneficiary.branch',
							'beneficiary.account_number',
							'beneficiary.account_type',
							'beneficiary.check_digit',
						]).then((valid) => {
							if (valid) validateBankAccount(getValues('beneficiary'));
						});
					}}
				>
					Avançar
				</Button>
			</S.ButtonsContainer>
		</S.GeneralContainer>
	);
};

const options = [
	'CRÉDITO EM CONTA CORRENTE',
	'PAGAMENTO DE ALUGUEL/CONDOMINIOS',
	'PAGAMENTO DE DUPLICATAS/TITULOS',
	'PAGAMENTO DE DIVIDENDOS',
	'PAGAMENTO DE MENSALIDADES ESCOLARES',
	'PAGAMENTO DE SALÁRIOS',
	'PAGAMENTO A FORNECEDORES',
	'PAGAMENTO DE ARRECADAÇÃO/PAGTO TRIBUTOS',
	'TRANSFERÊNCIA INTERNACIONAL EM REAIS',
	'TED PARA A POUPANÇA',
	'TED PARA DEPOSITO JUDICIAL',
	'PENSÃO ALIMENTICIA',
	'RESTITUIÇÃO DE IMPOSTO DE RENDA',
	'PAGAMENTO DE BOLSA AUXILIO',
	'REMUNERAÇÃO A COOPERADO',
	'PAGAMENTOS DE HONORÁRIOS',
	'PAGTO PREBENDA (REMUN.PADRES/SACERDOTES)',
	'PAGAMENTO DE VALE TRANSPORTE',
	'SIMPLES NACIONAL',
	'RESTITUIÇÃO DE TRIBUTOS-RFB',
	'REPASSE VALORES CONVÊNIO CENTRALIZADO',
	'REEMBOLSO DESP. OPERAÇÕES RENDA FIXA/VAR',
	'LEI ROUANET - PATROCINIO',
	'LEI ROUANET - DOAÇÃO',
	'PGTO MULTA BACEN ATRASO IMPORTAÇÃO',
	'REPASSE VALORES P/ FUNDEB',
	'TRANSF.C/C INST. NÀO BANC. P/ CTA LIQUID',
	'PAGAMENTO DE RESCISÃO CONTRATO TRABALHO',
	'CRÉDITO INVEST. CLIENTE IF CREDITADA',
	'DÉBITO INVEST. CLIENTE IF CREDITADA',
	'PAG. DE OPERAÇÕES DE CRÉDITO POR CLIENTE',
];

interface TransferProps extends StepProps {
	schedule: boolean;
	setSchedule: (value: boolean) => void;
	onBack: () => void;
}

const TransferData = ({
	form,
	setStep,
	schedule,
	setSchedule,
	onBack,
}: TransferProps) => {
	const { currentCompany } = useAuth();

	const getLimitQuery = useQuery(
		['getLimits', currentCompany?.id],
		() => getLimits(currentCompany!.id),
		{
			enabled: !!currentCompany,
			refetchOnWindowFocus: false,
			refetchOnMount: true,
		}
	);

	const limits = getLimitQuery.data;
	const { register, trigger, formState, watch, setValue, getValues } = form;
	const { companyBalance } = useAuth();

	const amount = watch('amount');
	const amountAfterTransfer = parseCurrencyToBRLStandard(
		companyBalance.corpway_balance - parseCurrencyStrToNumber(amount)
	);
	const scheduled_date = watch('scheduled_date');

	const isNightPeriod = (date: Date = new Date()) => {
		const hour = date.getHours();
		return hour >= 17 || hour <= 7;
	};

	const isWeekendDay = (date: Date = new Date()) => {
		const day = date.getDay();
		return day === 0 || day === 6;
	};

	const isHolidayMutation = useMutation(['isHoliday'], (date: Date) =>
		isNationalHoliday({ date })
	);

	async function onNextPressed() {
		if (!limits?.ted.day?.amount) {
			toast.error(
				'Estamos com um problema temporário, tente novamente mais tarde.'
			);
			return;
		}
		const scheduled_date = getValues('scheduled_date');
		const convertedAmount = convertReaisToCents(
			parseCurrencyStrToNumber(amount)
		);

		if (!scheduled_date) {
			// Check if the amount is greater than the day limit
			if ((limits?.ted.day?.amount ?? 0) < convertedAmount) {
				setStep('Limit warning');
				return;
			}

			// Check if is night period or weekend day
			if (
				isNightPeriod() ||
				isWeekendDay() ||
				(await isHolidayMutation.mutateAsync(new Date()))
			) {
				setStep('Hour Warning');
				return;
			}
		}

		if (scheduled_date) {
			// Check if the amount is greater than the day limit on scheduled date
			if ((limits?.ted.day.amount ?? 0) < convertedAmount) {
				setStep('Limit warning');
				return;
			}

			const date = new Date(`${scheduled_date}T00:00:00`);
			// Check if is weekend on scheduled date

			if (isWeekendDay(date) || (await isHolidayMutation.mutateAsync(date))) {
				setStep('Hour Warning');
				return;
			}
		}

		setStep(4);
	}

	function validateAmount() {
		trigger(['description', 'amount', "scheduled_date"]).then((valid) => {
			if (
				valid &&
				parseCurrencyStrToNumber(amount) <= companyBalance.corpway_balance &&
				!getValues('scheduled_date')
			) {
				onNextPressed();
				return;
			}
			if (valid && getValues('scheduled_date')) {
				onNextPressed();
				return;
			}
		});
	}

	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);

	return (
		<S.GeneralContainer>
			<S.FormContainer>
				<S.Title>Dados da transferência:</S.Title>
				<S.Row>
					{schedule && (
						<div style={{ width: '55%' }}>
							<S.MonthSelector className='month'>
								<IoMdCalendar size={23} />

								<InputComp
									type='date'
									autoComplete='off'
									min={parseDateToInput(tomorrow.toLocaleDateString())}
									value={
										typeof scheduled_date === 'string' ? scheduled_date : ''
									}
									onChange={(e) => {
										setValue('scheduled_date', e.target.value);
									}}
									style={{ paddingRight: 0 }}
									onBlur={(e) => {
										setValue('scheduled_date', e.target.value);
									}}
								/>
							</S.MonthSelector>
							{formState.errors.scheduled_date && (
								<S.ErrorMessage>
									<MdErrorOutline /> {formState.errors.scheduled_date.message}
								</S.ErrorMessage>
							)}
						</div>
					)}

					<Button
						type={'button'}
						roundness={'lg'}
						intent={'terciary'}
						style={{ padding: '1rem', fontSize: '1.2rem' }}
						onClick={() => {
							if (scheduled_date) setValue('scheduled_date', '');
							setSchedule(!schedule);
						}}
						$outline
					>
						Agendar tranferência
					</Button>
				</S.Row>

				<SelectField
					name={'description'}
					label={'Descrição:'}
					register={register}
					autoComplete='off'
					placeholder={'Selecione uma descrição'}
					errorMessage={formState.errors.description?.message}
				>
					{options.map((option) => (
						<option value={option} key={option}>
							{option}
						</option>
					))}
				</SelectField>

				<S.BorderContainer>
					<InputField
						name={'amount'}
						label={'Valor que deseja transferir:'}
						placeholder={'R$ 0,00'}
						autoComplete='off'
						register={register}
						onChange={(e) => {
							e.target.value = moneyMask(e.target.value);
						}}
						onBlur={(e) => {
							setValue('amount', moneyMask(e.target.value));
						}}
						errorMessage={formState.errors.amount?.message}
					/>
					<S.Row>
						<Typography size={'1.2rem'}>Saldo disponível</Typography>
						<Typography
							weight={'600'}
							size={'1.2rem'}
							color={'var(--primary-blue)'}
						>
							{parseCurrencyToBRLStandard(companyBalance.corpway_balance)}
						</Typography>
					</S.Row>
					<S.Row>
						<Typography size={'1.2rem'}>Saldo após transferência</Typography>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<IoMdArrowRoundDown size={18} color={'var(--primary-red)'} />
							<Typography weight={'600'} size={'1.2rem'} color={'#929292'}>
								{amountAfterTransfer}
							</Typography>
						</div>
					</S.Row>
					{amount &&
						!scheduled_date &&
						parseCurrencyStrToNumber(amount) >
							companyBalance.corpway_balance && (
							<S.Row>
								<Typography
									weight={'400'}
									size={'1.2rem'}
									color={'var(--primary-red)'}
								>
									Você não possui saldo suficiente em conta.
								</Typography>
							</S.Row>
						)}
					{amount &&
						scheduled_date &&
						parseCurrencyStrToNumber(amount) >
							companyBalance.corpway_balance && (
							<S.Row>
								<Typography
									weight={'400'}
									size={'1.2rem'}
									color={'var(--primary-red)'}
								>
									Seu saldo em conta é insuficiente, mas você ainda pode
									realizar o agendamento.
								</Typography>
							</S.Row>
						)}
				</S.BorderContainer>
			</S.FormContainer>
			<S.ButtonsContainer>
				<Button
					type={'button'}
					roundness={'lg'}
					intent={'terciary'}
					$outline
					onClick={onBack}
				>
					Voltar
				</Button>
				<Button
					type={'submit'}
					roundness={'lg'}
					intent={'primary'}
					loading={isHolidayMutation.isLoading}
					onClick={validateAmount}
				>
					Avançar
				</Button>
			</S.ButtonsContainer>
		</S.GeneralContainer>
	);
};

const Confirmation = ({ form, setStep }: StepProps) => {
	const { watch } = form;
	const { currentCompany } = useAuth();
	const beneficiary = watch('beneficiary');
	const amount = watch('amount');
	const transferType = watch('type');
	const scheduledDate = watch('scheduled_date');

	const isNightPeriod = (date: Date = new Date()) => {
		const hour = date.getHours();
		return hour >= 17;
	};

	function parseDate(date: string | Date | undefined) {
		if (!date && isNightPeriod()) {
			const tomorrow = new Date();
			tomorrow.setDate(tomorrow.getDate() + 1);
			return tomorrow;
		}

		if (!date) {
			return new Date();
		}

		if (typeof date === 'string') {
			return new Date(date + 'T00:00:00');
		}

		return date;
	}

	const [password, setPassword] = useState('');
	const [error, setError] = useState<string | undefined>(undefined);
	const getBanksQuery = useQuery(['banks'], fetchBankList);

	const onSuccess = () => {
		setError(undefined);
		setStep(5);
	};

	const { validateUserPassword, isPasswordValidationLoading } =
		useValidateCorpwayUserPassword({
			onSuccess: () => requestTokenMutation.mutate(),
			onFailure: () => setError('Senha incorreta, por favor digite novamente!'),
		});

	const requestTokenMutation = useMutation(
		['requestToken', currentCompany!.id],
		() => resentToken(currentCompany!.id),
		{
			onSuccess: onSuccess,
		}
	);

	const nextWorkingDayQuery = useQuery(['nextWorkingDay'], () =>
		getNextWorkingDay({ date: parseDate(scheduledDate) })
	);

	const selectedBank = getBanksQuery.data?.find(
		(bank) => bank.code === beneficiary.bank_code
	);

	return (
		<S.GeneralContainer>
			<S.FormContainer>
				<S.RowInformation>
					<S.Title>Dados do favorecido:</S.Title>
					<S.Row>
						<Typography>
							{beneficiary.type === 'pf' ? 'Nome completo:' : 'Razão social:'}
						</Typography>
						<Typography>{beneficiary.name}</Typography>
					</S.Row>
					<S.Row>
						<Typography>
							{beneficiary.type === 'pf' ? 'CPF:' : 'CNPJ:'}
						</Typography>
						<Typography>{beneficiary.document}</Typography>
					</S.Row>
				</S.RowInformation>

				<S.RowInformation>
					<S.Title>Dados bancários:</S.Title>
					<S.Row>
						<Typography>Banco:</Typography>
						<Typography>
							{selectedBank?.code} - {selectedBank?.name}
						</Typography>
					</S.Row>
					<S.Row>
						<Typography>Tipo de conta:</Typography>
						<Typography>
							{beneficiary.account_type === 'checking'
								? 'Corrente'
								: 'Poupança'}
						</Typography>
					</S.Row>
					<S.Row>
						<Typography>Agência:</Typography>
						<Typography>{beneficiary.branch}</Typography>
					</S.Row>
					<S.Row>
						<Typography>Conta:</Typography>
						<Typography>
							{beneficiary.account_number} - {beneficiary.check_digit}
						</Typography>
					</S.Row>
				</S.RowInformation>

				<S.RowInformation>
					<S.Row>
						<S.Title>Valor:</S.Title>
						<div>
							<Typography
								weight={'600'}
								color={'var(--primary-blue)'}
								size={'1.6rem '}
							>
								{parseCurrencyToBRLStandard(parseCurrencyStrToNumber(amount))}
							</Typography>
						</div>
					</S.Row>
					<S.Row>
						<Typography>Tipo de transferência:</Typography>
						<Typography>{transferType.toUpperCase()}</Typography>
					</S.Row>
					<S.Row>
						<Typography>Transferir em:</Typography>
						{nextWorkingDayQuery.data && (
							<Typography>
								{Intl.DateTimeFormat('pt-BR', {
									timeZone: 'UTC',
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
								}).format(nextWorkingDayQuery.data)}
							</Typography>
						)}
					</S.Row>
				</S.RowInformation>

				<S.BorderContainer>
					<S.Title>Segurança:</S.Title>
					<PasswordInput
						label={'Insira sua senha'}
						placeholder={'Digite sua senha de acesso'}
						value={password}
						ignorePasswordManager={true}
						showLeftIcon
						onChange={(e) => {
							setPassword(e.target.value);
							setError(undefined);
						}}
						errorMessage={error}
					/>
				</S.BorderContainer>

				<S.ButtonsContainer>
					<Button
						type={'button'}
						roundness={'lg'}
						intent={'terciary'}
						$outline
						onClick={() => setStep(3)}
					>
						Voltar
					</Button>
					<Button
						type={'submit'}
						roundness={'lg'}
						intent={'primary'}
						loading={
							isPasswordValidationLoading || requestTokenMutation.isLoading
						}
						onClick={() => {
							if (password === '') {
								setError('Informe sua senha');
								return;
							}
							validateUserPassword(password);
						}}
					>
						Avançar
					</Button>
				</S.ButtonsContainer>
			</S.FormContainer>
		</S.GeneralContainer>
	);
};

interface TokenStepProps extends StepProps {
	onSubmit: (data: Transfer) => void;
	isLoading: boolean;
}

const TokenValidation = ({
	form,
	setStep,
	onSubmit,
	isLoading,
}: TokenStepProps) => {
	const { user, currentCompany } = useAuth();
	const { handleSubmit, watch, setValue, formState, clearErrors } = form;

	const token = watch('token');
	const tokenParts = token.split('');

	//seconds
	const tokenMaxTime = 120;
	const tokenRequestMaxTime = 30;

	const resendTokenMutation = useMutation(
		['resendToken', currentCompany?.id],
		() => resentToken(currentCompany!.id),
		{
			onSuccess: () => {
				toast.info(`Token reenviado com sucesso para ${user.email}`);
				resetTimer();
			},
			onError: (err) => {
				showErrorMessage(err as Error, 'Não foi possível reenviar o token.');
			},
		}
	);

	const [seconds, setSeconds] = useState(tokenMaxTime);
	const [requestSeconds, setRequestSeconds] = useState(tokenRequestMaxTime);

	const [secondsIntervalId, setSecondsIntervalId] =
		useState<NodeJS.Timeout | null>(null);
	const [requestIntervalId, setRequestIntervalId] =
		useState<NodeJS.Timeout | null>(null);

	const startTimer = (
		setTime: React.Dispatch<React.SetStateAction<number>>,
		maxTime: number
	) => {
		const intervalId = setInterval(() => {
			setTime((prevSeconds) => {
				if (prevSeconds > 0) {
					return prevSeconds - 1;
				} else {
					clearInterval(intervalId);
					return 0;
				}
			});
		}, 1000);
		return intervalId;
	};

	useEffect(() => {
		const secondsIntervalId = startTimer(setSeconds, tokenMaxTime);
		const requestIntervalId = startTimer(
			setRequestSeconds,
			tokenRequestMaxTime
		);

		setSecondsIntervalId(secondsIntervalId);
		setRequestIntervalId(requestIntervalId);

		return () => {
			clearInterval(secondsIntervalId);
			clearInterval(requestIntervalId);
		};
	}, []);

	const resetTimer = () => {
		if (secondsIntervalId) clearInterval(secondsIntervalId);
		if (requestIntervalId) clearInterval(requestIntervalId);

		setSeconds(tokenMaxTime);
		setRequestSeconds(tokenRequestMaxTime);

		const newSecondsIntervalId = startTimer(setSeconds, tokenMaxTime);
		const newRequestIntervalId = startTimer(
			setRequestSeconds,
			tokenRequestMaxTime
		);

		setSecondsIntervalId(newSecondsIntervalId);
		setRequestIntervalId(newRequestIntervalId);

		clearErrors(['token']);
	};

	return (
		<S.GeneralContainer>
			<S.Title>Código token</S.Title>

			<S.RowInformation>
				<S.Title>Enviamos um e-mail para:</S.Title>

				<Typography>{user.email}</Typography>
			</S.RowInformation>
			<S.RowInformation>
				<Typography>
					<span>
						Com o código de confirmação dessa transação, por favor insira o
						código no campo abaixo:
					</span>
				</Typography>
			</S.RowInformation>
			<S.RowInformation>
				<Typography>
					<span>
						{seconds > 0 ? (
							<b>
								Token válido por{' '}
								{seconds > 60
									? Math.floor(seconds / 60) +
									  ' min e ' +
									  (seconds % 60) +
									  ' segundos'
									: seconds + ' segundos'}
							</b>
						) : (
							<b>Token expirado</b>
						)}
					</span>
				</Typography>
			</S.RowInformation>
			<S.CustomButton
				type={'button'}
				disabled={requestSeconds !== 0}
				onClick={() => resendTokenMutation.mutate()}
			>
				Não recebi o token ou o token expirou. <br /> Reenviar por e-mail
			</S.CustomButton>

			<S.BorderContainer>
				<S.Title>Token</S.Title>
				<S.TokenInput>
					<input
						name={'token'}
						autoComplete='off'
						autoFocus
						value={token}
						maxLength={6}
						onChange={(e) => {
							const value = e.target.value;
							setValue('token', value.trim().toUpperCase());
						}}
					/>
					<div
						className={
							tokenParts.length === 1 || tokenParts.length === 0 ? 'focus' : ''
						}
					>
						{tokenParts[0] ?? '-'}
					</div>
					<div className={tokenParts.length === 2 ? 'focus' : ''}>
						{tokenParts[1] ?? '-'}
					</div>
					<div className={tokenParts.length === 3 ? 'focus' : ''}>
						{tokenParts[2] ?? '-'}
					</div>
					<div className={tokenParts.length === 4 ? 'focus' : ''}>
						{tokenParts[3] ?? '-'}
					</div>
					<div className={tokenParts.length === 5 ? 'focus' : ''}>
						{tokenParts[4] ?? '-'}
					</div>
					<div className={tokenParts.length === 6 ? 'focus' : ''}>
						{tokenParts[5] ?? '-'}
					</div>
				</S.TokenInput>
				{formState.errors.token && (
					<Typography
						color={'var(--primary-red)'}
						weight={'400'}
						size={'1.2rem'}
					>
						{formState.errors.token.message}
					</Typography>
				)}
			</S.BorderContainer>
			<S.ButtonsContainer>
				<Button
					type={'button'}
					roundness={'lg'}
					intent={'terciary'}
					$outline
					onClick={() => setStep(4)}
				>
					Voltar
				</Button>
				<Button
					type={'submit'}
					roundness={'lg'}
					intent={'primary'}
					loading={isLoading}
					shrinkOnLoading={false}
					disabled={tokenParts.length !== 6 || seconds === 0}
					onClick={handleSubmit(onSubmit)}
				>
					Confirmar
				</Button>
			</S.ButtonsContainer>
		</S.GeneralContainer>
	);
};

export const Success = ({
	onClose,
	form,
}: {
	onClose: () => void;
	form: UseFormReturn<Transfer>;
}) => {
	const { watch } = form;
	const scheduledDate = watch('scheduled_date');
	return (
		<S.GeneralContainer>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2.4rem',
					alignItems: 'center',
				}}
			>
				<S.ModalIconContainer>
					<FaCheck size={24} color={'white'} />
				</S.ModalIconContainer>
				<S.Description>
					<span>
						Transferência {scheduledDate ? 'agendada ' : 'realizada '}{' '}
					</span>
					<br />
					<span className={'bold'}>com sucesso</span>
				</S.Description>
			</div>
			<S.ButtonsContainer>
				<Button roundness={'lg'} intent={'primary'} onClick={onClose}>
					Fechar
				</Button>
			</S.ButtonsContainer>
		</S.GeneralContainer>
	);
};

export {
	InitialStep,
	MainDataForm,
	BankDataForm,
	TransferData,
	Confirmation,
	TokenValidation,
};
