import React, { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import { UseFormRegister } from 'react-hook-form';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	label?: string | React.ReactNode;
	register?: UseFormRegister<any>;
	containerStyle?: React.CSSProperties;
	errorMessage?: string;
}

export default function TextAreaInput(props: Props) {
	return (
		<FieldSet style={props.containerStyle}>
			<Label htmlFor={props.name}>{props.label}</Label>
			<InputBase
				error={props.errorMessage}
				{...(props.register ? { ...props.register(props.name!) } : {})}
				{...props}
			/>
			{props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
		</FieldSet>
	);
}

const InputBase = styled.textarea<{ error?: string }>`
	width: 100%;
	color: var(--dark-gray);
	background-color: rgba(198, 198, 198, 0.33);
	border-radius: 0.4rem;
	border: none;
	padding: 0.4rem;
	min-height: 15rem;
	min-width: 45rem;
`;

export const Label = styled.label`
	margin-bottom: 0.5rem;
	color: var(--dark-gray);
	font-weight: 600;
	position: relative;
`;

export const FieldSet = styled.fieldset`
	border: none;
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
	width: fit-content;
`;

const ErrorMessage = styled.span<{ error?: string }>`
	color: red;
	font-size: 1.2rem;
	margin-top: 0.5rem;
`;
