import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Benefit } from '../../../@types';
import { showCancelableToast } from '../../../components/CancelableToast';
import { CancelPaymentModal } from '../../../components/CancelPaymentModal';
import Loader from '../../../components/Loader';
import { NotApprovedCollabsModal } from '../../../components/NotApprovedCollabsModal';
import PageTitle from '../../../components/PageTitle';
import { PaymentHeader } from '../../../components/PaymentHeader';
import { PaymentLogs } from '../../../components/PaymentLogs';
import PreventTransitionPrompt from '../../../components/PreventTransitionPrompt';
import { useAuth } from '../../../hooks/useAuth';
import { useDialogModal } from '../../../hooks/useDialogModal';
import { usePayment } from '../../../hooks/usePaymentV2';
import {
	cancelPayment,
	fetchPayment,
	GroupReleaseReviewAdminData,
	PreReleaseReviewAdminData,
	adminReviewPayment,
} from '../../../services/queries/PaymentsV2';
import { convertReaisToCents } from '../../../utils/CurrencyConvert';
import { parseCurrencyStrToNumber } from '../../../utils/parseCurrency';
import { parsePayment } from '../../../utils/parsePayment';
import { PaymentSummary } from './components/PaymentSummary';
import PaymentTableReviewAdmin from './components/PaymentTable/PaymentTableReviewAdmin';
import * as S from './styles';
import {
	getBenefitsInputs,
	getBenefitValue,
} from './utils/getBenefitValuesInputs';
import { showErrorMessage } from '../../../utils/ErrorHandler';

interface ReviewPaymentAdminParams {
	id: string;
}

interface ReviewPaymentParams {
	preReleases: PreReleaseReviewAdminData[];
	groupReleases: GroupReleaseReviewAdminData[];
}
export function ReviewPaymentAdmin() {
	const { id } = useParams<ReviewPaymentAdminParams>();
	const history = useHistory();
	const { currentCompany, updateCompanyBalance } = useAuth();
	const { beneficiaries, setBeneficiaries, setBenefits, setSubmitProgress, setSchedule } =
		usePayment();
	const { openConfirmDialog } = useDialogModal();
	const [showSummary, setShowSummary] = useState(false);

	const [allowNavigation, setAllowNavigation] = useState(false);

	const fetchPaymentQuery = useQuery(
		['fetchPayment', currentCompany?.id, id],
		() => {
			return fetchPayment(id!, currentCompany!.id);
		},
		{
			onSuccess: (data) => {
				const { beneficiariesParsed, benefitsParsed } = parsePayment(data);

				setBeneficiaries([
					...beneficiariesParsed.map((beneficiary) => {
						let beneficiaryBenefits = benefitsParsed.map((benefit) => {
							const foundBenefit = beneficiary.benefits.find(
								(b) => b.id === benefit.id
							);

							if (foundBenefit) {
								return foundBenefit;
							}
							// included {} empty benefit if not present (otherwise the table cells are going to break)
							return {} as Benefit;
						});

						return {
							...beneficiary,
							benefits: beneficiaryBenefits,
							selected: true,
						};
					}),
				]);
				setBenefits(benefitsParsed);
				setSchedule(data.release.scheduledDate ?? '')
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao buscar o lançamento. '
				);
			},
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
		}
	);

	const reviewPaymentMutation = useMutation(
		({ preReleases, groupReleases }: ReviewPaymentParams) =>
			adminReviewPayment(preReleases, groupReleases, id, currentCompany!.id),
		{
			onSuccess: () => {
				setAllowNavigation(true);
				setSubmitProgress(undefined);
				openConfirmDialog(`Pagamento revisado`, 'Entendi', () => {
					updateCompanyBalance.refetch();
					history.push('/home/payments');
				});
			},
			onError: (err) => {
				setSubmitProgress(undefined);
				showErrorMessage(
					err as Error,
					'Ocorreu um erro ao tentar revisar o lançamento.'
				);
			},
		}
	);

	const cancelPaymentMutation = useMutation(
		(reason_disapproval: string) => {
			return cancelPayment(reason_disapproval, id, currentCompany!.id);
		},
		{
			onSuccess: () => {
				setAllowNavigation(true);
				toast.info('Pagamento cancelado');
				history.push(`/home/payments/details/${id}`);
			},
			onError: (err) => {
				showErrorMessage(
					err as Error,
					'Ocorreu um problema ao cancelar o agendamento. '
				);
			},
		}
	);

	function showSummaryPayment() {
		if (
			getBenefitsInputs().some((i) => parseCurrencyStrToNumber(i.value) <= 0)
		) {
			toast.error('Benefício com valor abaixo do mínimo');
			return;
		}

		const repprovedAllBenefits = !beneficiaries.find(
			(beneficiary) =>
				!!beneficiary.benefits.find((b) => b.id && !b.reason_disapproval)
		);

		// all benefits were repproved, so don't show summary
		if (repprovedAllBenefits) {
			showConfirmationLoading();
		} else {
			setShowSummary(true);
		}
	}

	async function showConfirmationLoading() {
		setSubmitProgress('confirmation_loading'); // start loading

		const canceled = !(await showCancelableToast(
			'Enviando revisão',
			submitReview
		));
		if (canceled) {
			setSubmitProgress(undefined); // stop loading
		}
	}

	async function submitReview() {
		const release = fetchPaymentQuery.data!.release;

		let preReleases: PreReleaseReviewAdminData[] = [];
		let groupReleases: GroupReleaseReviewAdminData[] = [];

		// parsing collaborators
		release.preReleases.forEach((r) => {
			const collabBenefits = beneficiaries.find(
				(c) => c.id === r.collaborator.id
			)?.benefits;
			const currentBenefit = collabBenefits?.find(
				(b) => b.id === r.release_payment.benefit.id
			);
			const currentBenefitValue = getBenefitValue(
				r.collaborator.id!,
				currentBenefit!.id
			);

			// disapproved benefit
			if (currentBenefit?.reason_disapproval) {
				preReleases.push({
					preRelease_id: r.release_payment.id,
					reason_disapproval: currentBenefit.reason_disapproval,
				});
			} else if (
				// benefit value change
				Number(r.release_payment.benefit.value) !==
				currentBenefitValue * 100
			) {
				preReleases.push({
					preRelease_id: r.release_payment.id,
					value: convertReaisToCents(currentBenefitValue),
				}); // converting to cents
			}
		});

		// parsing groups
		release.groupReleases.forEach((r) => {
			const groupBenefits = beneficiaries.find(
				(c) => c.id === r.group.id
			)?.benefits;
			const currentBenefit = groupBenefits?.find(
				(b) => b.id === r.group_payment.benefit.id
			);
			const currentBenefitValue = getBenefitValue(
				r.group.id!,
				currentBenefit!.id
			);

			// disapproved benefit
			if (currentBenefit?.reason_disapproval) {
				groupReleases.push({
					groupRelease_id: r.group_payment.id,
					reason_disapproval: currentBenefit.reason_disapproval,
				});
			} else if (
				// benefit value change
				Number(r.group_payment.benefit.value) !==
				currentBenefitValue * 100
			) {
				groupReleases.push({
					groupRelease_id: r.group_payment.id,
					value: convertReaisToCents(currentBenefitValue),
				}); // converting to cents
			}
		});

		reviewPaymentMutation.mutate({ preReleases, groupReleases });
	}

	function goBack() {
		history.push('/home/payments');
	}

	if (
		!fetchPaymentQuery.data ||
		fetchPaymentQuery.isFetching ||
		cancelPaymentMutation.isLoading
	) {
		return (
			<S.Container>
				<PageTitle title='Detalhes do pagamento para aprovação' />
				<Loader />
			</S.Container>
		);
	}
	if (fetchPaymentQuery.data.release.status !== 'requested') {
		history.replace(`/home/payments/details/${id}`);
	}
	return (
		<>
			<S.Container>
				{!allowNavigation && <PreventTransitionPrompt />}

				<PaymentHeader
					title='Detalhes do pagamento para aprovação'
					release={fetchPaymentQuery.data!}
				/>

				{/* TABLE COMPONENT THAT RENDERS COLLABORATORS AND THEIR BENEFITS */}
				<div style={{ overflowX: 'auto', maxWidth: '80vw' }}>
					<PaymentTableReviewAdmin />
				</div>

				<S.FooterContainer>
					<CancelPaymentModal
						onCancel={async (reason) => cancelPaymentMutation.mutate(reason)}
					/>

					<S.SubmitionOptionsContainer>
						<S.CancellButton onClick={goBack}>Cancelar edições</S.CancellButton>
						<S.SubmitButton onClick={showSummaryPayment}>
							Revisar pagamento
						</S.SubmitButton>
					</S.SubmitionOptionsContainer>
				</S.FooterContainer>

				<PaymentLogs paymentId={id} />
			</S.Container>

			{/* MODALS */}
			<PaymentSummary
				onSubmit={showConfirmationLoading}
				filterDisapproved
				isOpenSummary={showSummary}
				closeSummary={() => setShowSummary(false)}
			/>

			<NotApprovedCollabsModal
				collabsReproved={reviewPaymentMutation.data?.unpaidPayments ?? []}
			/>
		</>
	);
}
